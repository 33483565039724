import React from 'react'

class Invoice extends React.Component {
  render() {
    return(
      <div>
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-md-5">
                    <div className="form-group">
                        <div className="search">
                            <input type="text" name="promotion" className="form-control" placeholder="Search" />
                            <i className="icon-search"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row justify-content-end">
                        <div className="col-md-10 col-lg-6 col-xl-6">
                            <div className="form-group form-date">
                                <input type="date" name="date" className="form-control" placeholder="Month" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="primary-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Month</th>
                                    <th>Number of promotions</th>
                                    <th>Total of invoice</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>May</td>
                                    <td>1958</td>
                                    <td>CH 25629</td>
                                    <td>
                                        <div className="download-button">
                                            <a href="#">Download <i className="icon-download"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>May</td>
                                    <td>1958</td>
                                    <td>CH 25629</td>
                                    <td>
                                        <div className="download-button">
                                            <button type="button">Download <i className="icon-download"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>May</td>
                                    <td>1958</td>
                                    <td>CH 25629</td>
                                    <td>
                                        <div className="download-button">
                                            <button type="button">Download <i className="icon-download"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>May</td>
                                    <td>1958</td>
                                    <td>CH 25629</td>
                                    <td>
                                        <div className="download-button">
                                            <button type="button">Download <i className="icon-download"></i></button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>May</td>
                                    <td>1958</td>
                                    <td>CH 25629</td>
                                    <td>
                                        <div className="download-button">
                                            <button type="button">Download <i className="icon-download"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                    <ul className="pagination">
                        <li className="page-item">
                            <a className="page-link" href="#"><i className="icon-arrow-left"></i></a>
                        </li>
                        <li className="page-item">
                            <a className="page-link active" href="#">1</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">2</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#">3</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" href="#"><i className="icon-arrow-right"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default Invoice
