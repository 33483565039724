var jQuery = require('jquery');

jQuery( document ).ready(function() {
  (function($) {
    "use strict";
    jQuery('.animated').appear(function() {
        var elem = jQuery(this);
        var animation = elem.data('animation');
        if ( !elem.hasClass('visible') ) {
            var animationDelay = elem.data('animation-delay');
            if ( animationDelay ) {
                setTimeout(function(){
                    elem.addClass( animation + " visible" );
                }, animationDelay);
            } 
            else {
                elem.addClass( animation + " visible" );
            }
        }
    });
    $( ".click-menu" ).click(function(event) {
        event.preventDefault();
        $(".toggle-responsive").toggleClass("open-menu");
        $( ".sub-header" ).toggleClass( "responsive");
        $( "body" ).toggleClass( "menu-overlay");
    });
  })(jQuery);
})

