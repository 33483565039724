import React
  from 'react';

import moment
  from 'moment';

import fetchUser
  from '../../../queries/user/singleUser'

import { graphql, compose }
  from 'react-apollo';

import { Route, Link }
  from "react-router-dom";

import fetchClients
  from '../../../queries/user/fetchClients';

import deleteUser
  from '../../../mutations/users/deleteUser'

import imageProfile
  from '../../../assets/images/images/apper.jpg';
import PromotesList
  from '../../../components/suppliers/PromotesList'

import { getTranslate }
  from 'react-localize-redux';

import { connect }
  from 'react-redux'

class Supplier extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      typeId: 1,
      sortBy: "createdAt",
      status: true
    }
  }

  removeUser(userId){
    this.props.mutate({
      variables: { id: userId },
      refetchQueries: [ { query: fetchClients } ]
    }).then(res => {
      window.location.href = '/dashboard/users';
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({errors: errors[0]})
    })
  }

  displayTopSellerResult() {
    let { user } = this.props.data
    if(user.topSeller) {

    }
  }

  onChangeFilter(event) {
    this.setState({typeId: event.target.value});
  }

  onChangeSort(event) {
    this.setState({sortBy: event.target.value});
  }

  onChangeStatus(event) {
    this.setState({status: event.target.value == "true" ? true : false});
  }

  render() {
    if(this.props.data.loading) { return <div /> }

    let { user } = this.props.data
    let { translate } = this.props
    let dateString = moment(parseInt(user.createdAt)).format('MMM-DD-YYYY');
    console.log("adads", this.state.status)
    return(
      <div>
        <div className="container page">
          <div className="row">
            <div className="col-md-12">
              <div className="page-head">
                <div className="left-info">
                  <div className="back">
                    <Link to={`/dashboard/suppliers/`}>
                      <i className="icon-arrow-left"></i>
                      <span>
                        { translate('dashboard.actions.back') }
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="right-info">
                  <div className="edit-button">
                    <Link to={`/dashboard/edit/suppliers/${user.id}`} >
                      <span>
                        { translate('dashboard.actions.edit') }
                      </span>
                      <i className="icon-edit"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12 d-flex justify-content-center">
                  <div className="supplier-thumb">
                    <img alt="" src={user.image} />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.name') }
                    </span>
                    <p>{ user.fullName }</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.email') }
                    </span>
                    <a href={`mailto:${ user.email }`}>{ user.email }</a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.phone') }
                    </span>
                    <a href="tel:+410012312312">{ user.phone }</a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.street') }
                    </span>
                    <p>{ user.street }</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.nr') }
                    </span>
                    <p>{ user.number }</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.zip') }
                    </span>
                    <p>{ user.zip }</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.city') }
                    </span>
                    <p>{ user.city }</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.filters.top_seller') }
                    </span>
                    <p style={user.topSeller ? {color: 'green'} : {color: 'red'}}>{user.topSeller ? 'Yes' : 'No'}</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.filters.register_date') }
                    </span>
                    <p>{ dateString }</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.website') }
                    </span>
                    <a href={user.webUrl} target="_blank">{ user.webUrl }</a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.paymentMethod') }
                    </span>
                    <a href={user.webUrl} target="_blank">{ user.paymentMethod }</a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="supplier-info">
                    <span>
                      { translate('dashboard.users.description') }
                    </span>
                    <p>
                      {user.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container m-t-50">
          <div class="row justify-content-between">
            <div class="col-md-4 d-flex align-items-center">
              <div class="list-text">
                <p>Total of promotions: <span>{user.promotes.length}</span></p>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row justify-content-end">
                <div class="col-md-4">
                  <div class="select-custom select-full-width">
                    <select class="form-control" onChange={this.onChangeFilter.bind(this)} value={this.state.typeId}>
                      <option value={1}>
                        { translate('dashboard.filters.all') }
                      </option>
                      <option value={2}>
                        { translate('dashboard.filters.sale') }
                      </option>
                      <option value={3}>
                        { translate('dashboard.filters.top_seller') }
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="select-custom select-full-width">
                    <select class="form-control" onChange={this.onChangeSort.bind(this)} value={this.state.sortBy}>
                      <option value={'createdAt'}>
                        { translate('dashboard.filters.created_at') }
                      </option>
                      <option value={'oldPrice'}>
                        { translate('dashboard.filters.old_price') }
                      </option>
                      <option value={'newPrice'}>
                        { translate('dashboard.filters.new_price') }
                      </option>
                      <option value={'discount'}>
                        { translate('dashboard.filters.discount') }
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="select-custom select-full-width">
                    <select class="form-control" onChange={this.onChangeStatus.bind(this)} value={this.state.status}>
                      <option value={true}>Active</option>
                      <option value={false}>Not Active</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PromotesList
          userId={user.id}
          typeId={this.state.typeId}
          sortBy={this.state.sortBy}
          status={this.state.status}
        />
        <div class="row">
          <div class="col-md-12 d-flex justify-content-center">
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ locale }){
  return { translate: getTranslate(locale) }
}

export default compose(
  connect(mapStateToProps),
  graphql(deleteUser),
  graphql(fetchUser, {
    options: (props) => {  return { variables: { id: props.match.params['supplier_id'] } } }
  }),
)(Supplier);
