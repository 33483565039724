import React from 'react';
import moment
  from 'moment';
import { Route, Link }
  from "react-router-dom";
import { connect }
   from 'react-redux';

import ShowSupplierList
  from './ShowSupplierList';
import fetchPromotesWithId
  from '../../queries/promote/fetchPromotesWithId';
import { getTranslate }
  from 'react-localize-redux';

import { compose, graphql }
  from 'react-apollo';

class PromotesList extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      filter: 1,
      sort: "createdAt",
      status: "Active"
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.typeId !== this.props.typeId || nextProps.sortBy !== this.props.sortBy || nextProps.status !== this.props.status) {
      this.handleSearchItem(nextProps.typeId, nextProps.sortBy, nextProps.status)
    }
  }

  handleSearchItem(typeId, sortBy, status) {
    const { refetch } = this.props.data
    refetch({userId: parseInt(this.props.userId), typeId: typeId, sortBy: sortBy, active: status})
  }

  displayPromotes() {
    var { promotesWithUserId } = this.props.data
    let { translate } = this.props;
    if(promotesWithUserId.length != 0)  {
      return (
        promotesWithUserId.map((promote) => {
          let dateString = moment(parseInt(promote.createdAt)).format('DD/MM/YYYY');
          return (
            <tbody>
              <tr>
                <td>{promote.id}</td>
                <td>{promote.title}</td>
                <td>
                  <div class="price">
                    <p>CHF <span>{promote.oldPrice}</span></p>
                  </div>
                </td>
                <td>
                  <div class="price">
                    <p>CHF <span>{promote.newPrice}</span></p>
                  </div>
                </td>
                <td>-{promote.discount}%</td>
                <td>{dateString}</td>
                <td>{promote.clicks}</td>
                <td>{promote.active ? 'Active' : 'Not Active'}</td>
              </tr>
            </tbody>
          )
        })
      )
    } else {
      return (
        <div className="promotion-item" style={{marginTop: 20}}>
          <p style={{textAlign: 'center'}}>
            { translate('dashboard.promotions.not_found_any_promotion') }
          </p>
        </div>
      )
    }
  }

  render () {
    if(this.props.data.loading) { return <div /> }
    var { promotesWithUserId } = this.props.data
    let { translate } = this.props;
    return(
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="primary-table">
              <table class="table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Saleprice</th>
                    <th>%</th>
                    <th>Date</th>
                    <th>Clicks</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {this.displayPromotes()}
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ locale, current_user, loading }){
  return { me: current_user, loading,
          translate: getTranslate(locale) }
}

export default compose(
  graphql(fetchPromotesWithId, {
    options: (props, state) => {  return { variables: { userId: parseInt(props.userId), typeId: props.typeId, sortBy: props.sortBy, active: props.status } } }
  }),
  connect(mapStateToProps, { })
)(PromotesList);

// <ul class="pagination">
//   <li class="page-item">
//     <a class="page-link" href="#"><i class="icon-arrow-left"></i></a>
//   </li>
//   <li class="page-item">
//     <a class="page-link active" href="#">1</a>
//   </li>
//   <li class="page-item">
//     <a class="page-link" href="#">2</a>
//   </li>
//   <li class="page-item">
//     <a class="page-link" href="#">3</a>
//   </li>
//   <li class="page-item">
//     <a class="page-link" href="#"><i class="icon-arrow-right"></i></a>
//   </li>
// </ul>
