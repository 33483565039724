import React from 'react'

import PromotionsList
  from '../../../components/promotions/PromotionsList';
import SupplierPromotionsList
  from '../../../components/promotions/SupplierPromotionsList';

import { Route, Link }
  from "react-router-dom";
import { connect }
   from 'react-redux';

import { graphql, compose }
  from 'react-apollo';
import fetchPromotions
  from '../../../queries/promote/fetchPromotions';

import searchPromotes
  from '../../../queries/promote/searchPromotes';
import { getTranslate }
  from 'react-localize-redux';
import LoadingGif
  from '../../../assets/images/images/lgLoader.gif';
import Select from 'react-select';
var _ = require('lodash');

class Promotion extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      promotes: '',
      searchItem: '',
      type: 1,
      typeName: { value: 1, label: this.props.translate('dashboard.filters.all') },
      sortbySelect: { value: 'created_at', label: this.props.translate('dashboard.filters.created_at') },
      sortBy: 'createdAt',
      types: [
        {value: 1, label: this.props.translate('dashboard.filters.all')},
        {value: 2, label: this.props.translate('dashboard.filters.sale')},
        {value: 3, label: this.props.translate('dashboard.filters.top_seller')},
        {value: 4, label: this.props.translate('dashboard.filters.on_slider') },
      ],
      sorts: [
        {value: 'createdAt', label: this.props.translate('dashboard.filters.created_at')},
        {value: 'oldPrice', label: this.props.translate('dashboard.filters.old_price')},
        {value: 'newPrice', label: this.props.translate('dashboard.filters.new_price')},
        {value: 'discount', label: this.props.translate('dashboard.filters.discount') },
      ]
    }
  }

  onChangeSort(event) {
    this.setState({sortBy: event.value});
    this.setState({sortbySelect: event});
  }

  onChangeType(event) {
    this.setState({type: event.value});
    this.setState({typeName: event});
  }

  setSearchTerm = _.debounce(searchItem => {
       this.setState({ searchItem })
   }, 1000)

  displayPromotesList() {
    if(this.props.me) {
      var { me, translate } = this.props
      if(me.roleId == 1) {
        return (
          <PromotionsList
            searchItem={this.state.searchItem}
            typeId={this.state.type}
            translate={translate}
            sortBy={this.state.sortBy}
            history={this.props.history}/>
        )
      } else if(me.roleId == 2) {
        return (
          <SupplierPromotionsList
            userId={this.props.me.id}
            translate={translate}
            searchItem={this.state.searchItem}
            typeId={this.state.type}
            sortBy={this.state.sortBy}
            history={this.props.history}/>
        )
      }
    }
  }

  render () {
    let { translate } = this.props;

    return(
      <div>
        <div className="container">
          <div className="row justify-content-between">
              <div className="col-md-12 col-lg-3 col-xl-3">
                <div className="form-group">
                    <div className="search">
                        <input
                          type="text"
                          name="promotion"
                          className="form-control"
                          placeholder={ translate('dashboard.promotions.search_promotion') }
                          onChange={e => {this.setSearchTerm(e.target.value)}}
                          />
                        <i className="icon-search"></i>
                    </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-9 col-xl-9">
                  <div className="row">
                      <div className="col-md-3">
                          <div className="select-custom select-full-width">
                            <Select
                              value={this.state.typeName}
                              onChange={this.onChangeType.bind(this)}
                              options={this.state.types}
                            />
                          </div>
                      </div>
                      <div className="col-md-3">
                        <div className="select-custom select-full-width">
                          <Select
                            value={this.state.sortbySelect}
                            onChange={this.onChangeSort.bind(this)}
                            options={this.state.sorts}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="import-button">
                          <span>Import Promotions</span>
                          <Link
                            className={"add"}
                            to={`/dashboard/promotions/import`}
                            ><i class="icon-upload"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-3">
                          <div className="add-button">
                            <span>
                               { translate('dashboard.promotions.add_promotion') }
                            </span>
                              <Link
                                to={`/dashboard/promotions/new`}
                                className="add">
                                <i className="icon-add"></i>
                              </Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        {this.displayPromotesList()}
      </div>
    )
  }
}


function mapStateToProps({ locale, current_user, loading }){
  return { me: current_user, loading,
          translate: getTranslate(locale) }
}

export default compose(
  connect(mapStateToProps, { })
)(Promotion);
