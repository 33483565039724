import gql from 'graphql-tag';

export default gql`
  query promoteClicksUser($id: ID!, $typeId: ID!, $sortBy: String!, $active: Boolean) {
    promoteClicksUser(id: $id,typeId: $typeId,sortBy: $sortBy,active: $active) {
      id
      title
      oldPrice
      newPrice
      discount
      createdAt
      clicks
      active
    }
  }
`;
