import React from 'react';

import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';

import fetchSuppliers
  from '../../../queries/user/fetchSuppliers';

import SuppliersList
  from '../../../components/suppliers/SuppliersList';

import SearchedItems
  from '../../../components/users/SearchedItems';

import { getTranslate }
  from 'react-localize-redux';

import { connect }
   from 'react-redux';
import LoadingGif
 from '../../../assets/images/images/lgLoader.gif'

import LIMIT
  from '../../../constants/pagination_limit';
import deleteUser
  from '../../../mutations/users/deleteUser';
import Select from 'react-select';
var _ = require('lodash');
const OFFSET = 0

class Supliers extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      search: '',
      offset: OFFSET,
      sort: 'createdAt',
      sortSelect: null,
      sorts: [
        {value: 'fullName', label: this.props.translate('dashboard.users.name')},
        {value: 'createdAt', label: this.props.translate('dashboard.filters.register_date')},
        {value: 'email', label: this.props.translate('dashboard.users.email') },
      ]
    }
  }

  sortOnChange(event){
    this.setState({
      sort: event.value, sortSelect: event
    })

    this.props.data.refetch({ order: event.value })
  }

  changeOffset(e, offset){
    e.preventDefault();

    let { suppliers } = this.props.data;
    let times = Math.round(suppliers.count/LIMIT)

    if(suppliers.rows && times < offset ){ return null; }

    this.setState({
      offset
    })

    this.props.data.refetch({ order: 'createdAt', offset: LIMIT * offset, limit: LIMIT })
  }

  setSearchTerm = _.debounce(search => {
     this.setState({ search })
  }, 1000)

  render () {
    let { loading, suppliers } = this.props.data
    let { translate } = this.props;

    if(loading) {
      return (
        <div style={{width: 200, margin: 'auto', marginTop: 200}}>
          <img alt="" src={LoadingGif} />
        </div>
      )
    }

    return(
      <div>
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                        <div className="search">
                             <input type="text" name="promotion"
                                    onChange={e => {this.setSearchTerm(e.target.value)}}
                                    className="form-control"
                                    placeholder={ translate('dashboard.users.search_supplier')}/>
                            <i className="icon-search"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 col-lg-7 col-xl-7">
                    <div className="row">
                        <div className="col-md-4">
                          { /* <div className="select-custom select-full-width">
                                <select className="form-control">
                                    <option defaultValue="" disabled="">Filter</option>
                                    <option>test 1</option>
                                    <option>test 2</option>
                                </select>
                            </div> */ }
                        </div>
                        <div className="col-md-4">
                            <div className="select-custom select-full-width">
                              <Select
                                value={this.state.sortSelect}
                                onChange={this.sortOnChange.bind(this)}
                                options={this.state.sorts}
                                placeholder={translate('dashboard.filters.sort_by')}
                              />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="add-button">
                              <span>
                                { translate('dashboard.users.add_suppliers')}
                              </span>
                                <Link to={`/dashboard/new/suppliers`} className='add'>
                                  <i className="icon-add"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        { this.state.search != '' ?
         <SearchedItems mutate={this.props.mutate}
                        type='supplier'
                        translate={translate}
                        offset={this.state.offset}
                        search={this.state.search} />
        :
         <SuppliersList mutate={this.props.mutate}
                        offset={this.state.offset}
                        translate={translate}
                        changeOffset={this.changeOffset.bind(this)}
                        suppliers={suppliers} />
        }
      </div>
    )
  }
}

function mapStateToProps({locale}){
  return {  translate: getTranslate(locale) }
}

export default compose(
  connect(mapStateToProps),
  graphql(deleteUser),
  graphql(fetchSuppliers, {
    options: (props) => {  return { variables: { order: 'createdAt', offset: OFFSET, limit: LIMIT } } }
  })
)(Supliers);
