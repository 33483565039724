import React from 'react'

const Report = (props) => {
  return(
    <div>
      <p>Report</p>
    </div>
  )
}

export default Report
