import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";
import { graphql, compose }
  from 'react-apollo';

class NewPromoteInputs extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      mainImage: 'https://upload.wikimedia.org/wikipedia/commons/7/7e/Circle-icons-profile.svg',
      image1: '',
      image2: 'https://zsuttonphoto.com/wp-content/uploads/2014/08/Photography-Headshots-New-Mexico.jpg',
      image3: '',
      image4: '',
    }
  }

  displayMainImage() {
    if(this.props.mainImage) {
      return (
        <img
          src={typeof this.props.mainImage == 'string' ? this.props.mainImage : URL.createObjectURL(this.props.mainImage)}
          style={{width: 176, height: 176}}
        />
      )
    } else {
      return (
        <div className="drag-text">
          <span>Upload Image</span>
        </div>
      )
    }
  }

  displayGaleryImage(image) {
    if(image){
      return (
        <img
          src={typeof image == 'string' ? image : URL.createObjectURL(image) }
          style={{width: 176, height: 176}}
        />
      )
    } else {
      return (
        <div className="drag-text">
          <span>Upload Image</span>
        </div>
      )
    }
  }

  render () {
    return(
      <div className="row">
        <div className="col-md-12 d-flex justify-content-center">
            <div className="main-file-upload ">
                <div className="title">
                    <p>Main image</p>
                </div>
                <div className="file-upload">
                    <div className="image-upload-wrap">
                      <input
                        type="file"
                        className="file-upload-input"
                        accept="image/*"
                        onChange={(e) => this.props.handleImageChange(e, "mainImage")} />
                      {this.displayMainImage()}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-12">
            <div className="gallery-title">
                <p>Gallery image</p>
            </div>
        </div>
        <div className="col-md-3">
            <div className="gallery-file-upload">
                <div className="file-upload">
                    <div className="image-upload-wrap">
                        <input
                          type="file"
                          className="file-upload-input"
                          accept="image/*"
                          onChange={(e) => this.props.handleImageChange(e, "image1")} />
                        {this.displayGaleryImage(this.props.image1)}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-3">
            <div className="gallery-file-upload">
                <div className="file-upload">
                    <div className="image-upload-wrap">
                        <input
                          type="file"
                          className="file-upload-input"
                          accept="image/*"
                          onChange={(e) => this.props.handleImageChange(e, "image2")} />
                        {this.displayGaleryImage(this.props.image2)}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-3">
            <div className="gallery-file-upload">
                <div className="file-upload">
                    <div className="image-upload-wrap">
                      <input
                        type="file"
                        className="file-upload-input"
                        accept="image/*"
                        onChange={(e) => this.props.handleImageChange(e, "image3")} />
                        {this.displayGaleryImage(this.props.image3)}
                    </div>
                </div>
            </div>
        </div>
        <div className="col-md-3">
            <div className="gallery-file-upload">
                <div className="file-upload">
                    <div className="image-upload-wrap">
                      <input
                        type="file"
                        className="file-upload-input"
                        accept="image/*"
                        onChange={(e) => this.props.handleImageChange(e, "image4")} />
                        {this.displayGaleryImage(this.props.image4)}
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default compose()(NewPromoteInputs)
