import gql from 'graphql-tag';

export default gql`
    query{
      categoryOptions{
        id
        name
      }
    }
`;

