import React from 'react'

import FavIvonLogo
  from '../../assets/images/logo_favicon/logo.svg';

// graphql define
import { graphql, compose }
  from 'react-apollo'

import fetchUserWithResetPassword
  from '../../queries/userWithResetPasswordToken';
import resetPassword
  from '../../mutations/auth/resetPassword';

class ResetPassword extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      newPassword: '',
      passwordConfirmation: '',
      error: ''
    }
  }

  requestChangePassword() {
    var { newPassword, passwordConfirmation } = this.state
    var { userWithPasswordResetToken } = this.props.data

    if(newPassword == '' && passwordConfirmation == '') {
      this.setState({error: "Password fields are empty!"})
      setTimeout( () => {
        this.setState({error: ''})
        },
      2000);
      return
    }

    if(newPassword != passwordConfirmation) {
      this.setState({error: 'Your password and confirmation password do not match.'})
      setTimeout( () => {
        this.setState({error: ''})
        },
      2000);
      return
    }

    this.props.mutate({
      variables: { userId: parseInt(userWithPasswordResetToken.id), newPassword: newPassword },
    }).then(res => {
      alert("Password change successfully")
      this.props.history.push('/auth')
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({errors: errors[0]})

      setTimeout( () => {
        this.setState({errors: ''})
        },
      3000);
    })
  }

  displayForm() {
    var { userWithPasswordResetToken } = this.props.data
    if(userWithPasswordResetToken != null) {
      return (
        <div>
          <div className="description">
            <h3>New Password</h3>
            <p>Change your password</p>
          </div>
          <p style={{color: 'red', marginBottom: 10}}>{this.state.error}</p>
          <div className="form-group">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="New Password"
              onChange={(e) => this.setState({newPassword: e.target.value})}  />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Confirm Password"
              onChange={(e) => this.setState({passwordConfirmation: e.target.value})} />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary btn-full-width"
              onClick={() => this.requestChangePassword()} >Confirm</button>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="description">
            <p>Token is not valid</p>
          </div>
        </div>
      )
    }
  }

  render () {
    var { loading, userWithPasswordResetToken } = this.props.data
    if(loading) {
      return (
        <div></div>
      )
    } else {
      return (
        <div className="content" data-animation="fadeIn" data-animation-delay="150">
          <div className="logo">
            <img src={FavIvonLogo} />
          </div>
          {this.displayForm()}
        </div>
      )
    }
  }
}

export default compose(
  graphql(resetPassword),
  graphql(fetchUserWithResetPassword, {
    options: (props) => {  return { variables: { passwordToken: props.match.params['token']} } }
  })
)(ResetPassword)
