import React from 'react'

import { Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';
import forgotPassword
  from '../../mutations/auth/forgotPassword';

import FavIvonLogo
  from '../../assets/images/logo_favicon/logo.svg'

class ForgotPassowrd extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      email: '',
      errors: '',
    }
  }

  requestResetPassword() {
    let { email } = this.state;

    if(email == '') {
      this.setState({errors: 'Email is blank'})

      setTimeout( () => {
        this.setState({errors: ''})
        },
      2000);

      return
    }

    this.props.mutate({
      variables: { email },
    }).then(res => {
      this.setState({
        email: ''
      })
      alert("Please check your email and click on the provided link to reset your password")
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({errors: errors[0]})

      setTimeout( () => {
        this.setState({errors: ''})
        },
      3000);
    })
  }

  render() {
    return(
      <div className="content" data-animation="fadeIn" data-animation-delay="150">
        <div className="logo">
          <img src={FavIvonLogo} />
        </div>
        <div className="description">
          <h3>Forgot Password</h3>
          <p>Please enter your email.</p>
        </div>
          <div className="form-group">
          <p style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Email"
              value={this.state.email}
              onChange={(e) => this.setState({email: e.target.value})} />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary btn-full-width"
              onClick={() => this.requestResetPassword()}>Send</button>
          </div>
      </div>
    )
  }
}

export default compose(
  graphql(forgotPassword),
)(ForgotPassowrd);
