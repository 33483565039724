import React from 'react';

import EditPromoteInputs
  from '../../../components/promotions/EditPromoteInputs';
import { startLoading, moveLoading }
  from '../../../actions/loading';

import fetchCategories
  from '../../../queries/category/fetchCategoryOptions';
import { connect }
   from 'react-redux';

import { graphql, compose }
 from 'react-apollo';
import { getTranslate } 
  from 'react-localize-redux';


class EditPromote extends React.Component {

  render() {
    let { loading, categoryOptions } = this.props.data
    let { translate } = this.props

    if(loading) {
      return <div></div>
    }

    return(
      <div className="container page">
        <div className="row">
          <div className="col-md-12">
            <div className="page-head">
              <div className="page-title">
                <h5>
                  { translate('dashboard.promotions.edit_promotion') }
                </h5>
              </div>
              <div className="right-info">
                <div className="close-button">
                  <a onClick={() => this.props.history.goBack()}>
                    <span>
                      { translate('dashboard.users.close')}
                    </span>
                    <i className="icon-close"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <EditPromoteInputs
            {...this.props}
            categories={this.props.data.categoryOptions} />
        </div>
      </div>
    )
  }
}

function mapStateToProps({ locale, current_user, loading }){
  return { me: current_user, loading,
          translate: getTranslate(locale) }
}

export default compose(
  graphql(fetchCategories),
  connect(mapStateToProps, { startLoading, moveLoading })
)(EditPromote);
