import React from 'react'

import Image1
  from '../../assets/images/images/jacket.jpg'
import Image2
  from '../../assets/images/images/smart-watch.jpg';

import MostClicks
  from '../../components/statistics/MostClicks';
import TopSeller
  from '../../components/statistics/TopSeller';

import MostClicksSuppplierPromotes
  from '../../components/statistics/MostClicksSuppplierPromotes';
import TopSellerSuppplierPromotes
  from '../../components/statistics/TopSellerSuppplierPromotes';
import LineChart
  from '../../components/statistics/LineChart';

import { getTranslate }
  from 'react-localize-redux';

import { connect }
   from 'react-redux';
import { graphql, compose }
 from 'react-apollo';
import { Chart } from "react-charts";

class Statistic extends React.Component {

  displayMostClicksPromotes() {
    var { roleId } = this.props.me
    let { translate } = this.props;

    if( roleId == 1) {
      return (
        <MostClicks
          translate={translate}
        />
      )
    } else if (roleId == 2) {
      return (
        <MostClicksSuppplierPromotes
          translate={translate}
          userId={this.props.me.id}/>
      )
    }
  }

  displayTopSellerPromotes() {
    var { roleId } = this.props.me
    let { translate } = this.props;

    if(roleId == 1) {
      return (
        <TopSeller translate={translate} />
      )
    } else if (roleId == 2) {
      return (
        <TopSellerSuppplierPromotes
          translate={translate}
          userId={this.props.me.id}/>
      )
    }
  }

  render() {
    let { translate } = this.props;

    if(!this.props.me) {
      return (
        <div></div>
      )
    }

    return(
      <div>
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="box">
                        <div className="head">
                            <div className="title">
                              <h5>
                                { translate('dashboard.dashboard.amount_suppliers')}
                              </h5>
                            </div>
                            <div className="filter">
                                <form>
                                    <div className="select-custom">
                                        { /*<select className="form-control">
                                          <option>
                                            {translate('dashboard.filters.day')}
                                          </option>
                                          <option>
                                            {translate('dashboard.filters.week')}
                                          </option>
                                          <option>
                                            {translate('dashboard.filters.month')}
                                          </option>
                                        </select> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="body">

                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                    <div className="box">
                        <div className="head">
                            <div className="title">
                              <h5>
                                { translate('dashboard.dashboard.amount_promotions')}
                              </h5>
                            </div>
                            <div className="filter">
                                <form>
                                    <div className="select-custom">
                                        <select className="form-control">
                                          <option>
                                            {translate('dashboard.filters.day')}
                                          </option>
                                          <option>
                                            {translate('dashboard.filters.week')}
                                          </option>
                                          <option>
                                            {translate('dashboard.filters.month')}
                                          </option>
                                        </select>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="body">

                        </div>
                    </div>
                </div>
                {this.displayTopSellerPromotes()}
                {this.displayMostClicksPromotes()}
            </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ current_user, locale, loading }){
  return { me: current_user,
          translate: getTranslate(locale),
          loading }
}

export default compose(
  connect(mapStateToProps, { })
)(Statistic);
