import React from 'react';
import moment from 'moment';

import { Route, Link }
  from "react-router-dom";

import fetchSuppliers
  from '../../queries/user/fetchSuppliers';

import LIMIT
  from '../../constants/pagination_limit';

export default class ShowSupplierList extends React.Component {

  removeUser(userId){
    let queries;

    if(this.props.search){
      queries = [ { query: fetchSuppliers, variables: { order: 'createdAt', offset: this.props.offset, limit: LIMIT }  },
        { query: this.props.query, variables: { search: this.props.search } }
      ]
    }else{
      queries = [ { query: fetchSuppliers, variables: { order: 'createdAt', offset: this.props.offset, limit: LIMIT }  } ]
    }

    this.props.mutate({
      variables: { id: userId },
      refetchQueries: queries
    }).then(res => {
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({errors: errors[0]})
      console.log(errors)
    })
  }

  displayList() {
    var { users } = this.props

    if(!users) { users = [] }
    return (
      users.map((client, idx) => {
        var dateString = moment(client.createdAt).format("MMM-DD-YYYY");
        return (
            <tr key={idx}>
              <td>
                <Link to={`/dashboard/suppliers/${client.id}`}>
                  {client.fullName}
                </Link>
              </td>
              <td>{client.email}</td>
              <td>{dateString}</td>
              <td>{client.category && client.category.name }</td>
              <td>{client.country}</td>
              <td>
                <div className="action-table">
                  <Link to={`/dashboard/edit/suppliers/${client.id}`} className="edit">
                    <i className="icon-edit"></i>
                  </Link>
                  <a href="#"
                    className="remove"
                    onClick={(e) => { if (window.confirm('Are you sure you want to delete this user?'))
                                            this.removeUser(client.id) }} >
                    <i className="icon-trash"></i>
                  </a>
                </div>
              </td>
            </tr>
        )
      })
    )
  }

  showPagination(){
    let paginations = []
    let times = Math.ceil(this.props.totalItems/LIMIT)
    for(let i=0; i <= times - 1; i++){
      paginations.push(<li key={i} className="page-item">
        <a className={`page-link ${this.props.offset == i ? 'active' : ''}`}
           onClick={(e) => this.props.changeOffset(e, i)}>
          { i+1 }
        </a>
      </li>)
    }

    return paginations
  }

  render () {
    let times = Math.ceil(this.props.totalItems/LIMIT)
    let { translate } = this.props

    return(
      <div className="container">
          <div className="row">
              <div className="col-md-12">
                  <div className="primary-table">
                      <table className="table">
                          <thead>
                              <tr>
                                <th>
                                  { translate('dashboard.users.name') }
                                </th>
                                <th>
                                  {translate('dashboard.users.email')}
                                </th>
                                <th>
                                  {translate('dashboard.users.register')}
                                </th>
                                <th>
                                  {translate('dashboard.users.country')}
                                </th>
                                <th>
                                  {translate('dashboard.users.action')}
                                </th>
                              </tr>
                          </thead>
                          <tbody>
                            {this.displayList()}
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                { ( this.props.offset || this.props.offset == 0 ) &&
                  <ul className="pagination">
                    {
                       this.props.offset != 0 &&
                      <li className="page-item">
                        <a className="page-link"
                          onClick={(e) => this.props.changeOffset(e, this.props.offset - 1)}
                          href="#"><i className="icon-arrow-left"></i></a>
                      </li>
                    }
                      { this.showPagination() }
                    {
                      this.props.offset < times-1 &&
                      <li className="page-item">
                        <a className="page-link"
                          onClick={(e) => this.props.changeOffset(e, this.props.offset + 1)}
                          href="#"><i className="icon-arrow-right"></i></a>
                      </li>
                   }
                  </ul>
                }
              </div>
          </div>
      </div>
    )
  }
}
