import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';
import FavIvonLogo1
  from '../../assets/images/images/jacket.jpg';
import searchPromotes
  from '../../queries/promote/searchPromotes';
import fetchPromotions
  from '../../queries/promote/fetchPromotions';
import importPromote
  from '../../mutations/dashboard/promotes/importPromote';
import { connect }
   from 'react-redux';
 import { startLoading, moveABitLoading, moveLoading }
   from '../../actions/loading'
import Loader from 'react-loader-spinner';

class ImportedPromote extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      xlsFile: null,
      error: null,
      importItems: [],
      step: 1,
      loading: false
    }
  }

  displayPromotes() {
    let { importItems } = this.props

    return (
      importItems.map((importItem) => {
        if(importItem.error != null) {
          return (
            <tbody key={importItem.index}>
              <tr>
              <td style={{color: "red"}}>{importItem.index}</td>
              <td style={{color: "red"}}>{importItem.error}</td>
              <td>
              </td>
              <td>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              </tr>
            </tbody>
          )
        } else {
          let { items } = importItem
          return (
            <tbody key={importItem.index}>
              <tr>
                <td>{importItem.index}</td>
                <td>{items.title}</td>
                <td>
                  <div class="price">
                    <p>CHF <span>{items.oldPrice}</span></p>
                  </div>
                </td>
                <td>
                  <div class="price">
                    <p>CHF <span>{items.newPrice}</span></p>
                  </div>
                </td>
                <td>-{items.discount}%</td>
                <td>{moment(items.startDate).format('DD/MM/YYYY')}</td>
                <td>{moment(items.endDate).format('DD/MM/YYYY')}</td>
                <td>{items.topSeller ? "Yes" : "No"}</td>
                <td>{items.active ? "Yes" : "No"}</td>
              </tr>
            </tbody>
          )
        }
      })
    )
  }

  displayButton() {
    if(this.state.loading) {
      return (
        <Loader
          type="Bars"
          color="#ff0844"
          height="50"
        />
      )
    } else {
      return(
        <a href="#" onClick={() => this.submitButton()} class="btn btn-primary">Import</a>
      )
    }
  }

  displayCancelButton() {
    if(this.state.loading) {
      return (
        <Loader
          type="Bars"
          color="#ff0844"
          height="50"
        />
      )
    } else {
      return (
        <a onClick={() => this.props.goBackStep()}>
          <i class="icon-cancel"></i>
          <span style={{color: "red"}}>Cancel</span>
        </a>
      )
    }
  }

  async submitButton() {
    let { importItems } = this.props
    this.setState({loading: true})
    this.props.startLoading()
    await importItems.map(async (importItem) => {
      if(importItem.error) {
        console.log("error", importItem)
      } else {
        await this.handleMutate(importItem.items)
      }
    })

    this.props.moveLoading()
    this.setState({loading: false})
    this.props.historyBack()
    this.props.historyBack()
  }

  handleMutate(item) {
    let { startLoading,
          moveLoading } = this.props;

    this.props.startLoading()
    this.setState({loading: true})
    this.props.mutate({
      mutation: importPromote,
      variables: { title: item.title, userId: parseInt(this.props.me.id), oldPrice: parseFloat(item.oldPrice), description: item.description,
                   newPrice: parseFloat(item.newPrice), discount: parseFloat(item.discount), categoryName: item.category,
                   startTime: Date.parse(item.startDate).toString(), endTime: Date.parse(item.endDate).toString(),
                   topSeller: item.topSeller, active: item.active, mainImage: item.mainImage},
      refetchQueries: [
        {
          query: searchPromotes,
          variables: { language: "en", search: "", typeId: 1, sortBy: 'createdAt' }
        }
      ]
    }).then(res => {
      this.props.moveABitLoading()
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      alert(errors)
    })

    this.props.moveLoading()
  }

  render () {
    return(
      <div class="container page">
        <div class="row">
          <div class="col-md-12">
            <div class="page-head">
              <div class="left-info">
                <div class="back">
                  {this.displayCancelButton()}
                </div>
              </div>
              <div class="page-title">
                <h5>Imported Promotions</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="primary-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Index</th>
                      <th>Title</th>
                      <th>Price</th>
                      <th>Saleprice</th>
                      <th>Discount</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Top Seller</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  {this.displayPromotes()}
                </table>
              </div>
              {this.displayButton()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ current_user }){
  return { me: current_user }
}

export default compose(
  graphql(importPromote),
  graphql(fetchPromotions),
  connect(mapStateToProps, { startLoading, moveABitLoading, moveLoading })
)(ImportedPromote)
