import { combineReducers } 
  from 'redux';
import currentUserReducer
  from './currentUser'
import loadingReducer 
  from './loading'
import { localeReducer } 
  from 'react-localize-redux';

const appReducer = combineReducers({
  current_user: currentUserReducer,
  loading: loadingReducer,
  locale: localeReducer
});

export default appReducer;
