import React from 'react';
import moment from 'moment';

import { graphql, compose }
  from 'react-apollo';
import fetchPromote
  from '../../../queries/promote/fetchPromote';
import searchPromotes
  from '../../../queries/promote/searchPromotes';

import deletePromote
  from '../../../mutations/dashboard/promotes/deletePromote';
import { Route, Link }
  from "react-router-dom";

import { connect }
   from 'react-redux';
import { getTranslate }
  from 'react-localize-redux';

class ShowPromote extends React.Component {
  requestToDeletePromote(promoteId) {
    var result = window.confirm("Do you want to delete?");
    if (result) {
      this.props.mutate({
        mutation: deletePromote,
        variables: { promoteId: parseInt(promoteId)},
        refetchQueries: [
          {
            query: searchPromotes,
            variables: { language: "en", search: "", typeId: 1, sortBy: 'createdAt' }
          }
        ]
      }).then(res => {
        alert("Delete successfully")
        this.props.history.push('/dashboard/promotions')
      }).catch(res => {
        alert(res)
      })
      console.log("ok")
    }
  }

  editEndDeleteActions() {
    let { translate } = this.props
    return (
      <div className="right-info edit-remove">
        <Link to={`/dashboard/promotions/edit/${this.props.match.params['promote_id']}`}
          className='edit'  >
          <i class="icon-edit"></i>
        </Link>
        <a href="#" className="remove"
          style={{color: "red"}}
          onClick={() => this.requestToDeletePromote(this.props.match.params['promote_id'])}>
          <i class="icon-trash"></i>
        </a>
      </div>
    )
  }

  displayEditEndDeleteActions() {
    let { promote } = this.props.data
    if(this.props.me) {
      if(this.props.me.roleId != 1) {
        if(parseInt(promote.user.id) == parseInt(this.props.me.id)) {
          return (
            this.editEndDeleteActions()
          )
        }
      } else {
        return (
          this.editEndDeleteActions()
        )
      }
    }
  }

  displayStatus() {
    let { promote } = this.props.data

    let expire_date = moment(parseInt(promote.endTime)) < moment()
    console.log(expire_date)

    if(expire_date == true) {
      return (
        <p style={{color: "red"}}>Expire date</p>
      )
    } else {
      return (
        <p style={{color: "green"}}>Active date</p>
      )
    }
  }

  render() {
    let { loading, promote } = this.props.data
    let no_image = "https://cdn11.bigcommerce.com/s-gho61/stencil/31cc7cb0-5035-0136-2287-0242ac11001b/e/3dad8ea0-5035-0136-cda0-0242ac110004/images/no-image.svg"
    let { translate } = this.props

    if(loading) {
      return <div></div>
    }

    return(
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-head">
              <div className="left-info">
                <div className="back">
                  <a onClick={() => this.props.history.goBack()}>
                    <i className="icon-arrow-left"></i>
                    <span>
                      { translate('dashboard.actions.back') }
                    </span>
                  </a>
                </div>
              </div>
              {this.displayEditEndDeleteActions()}
            </div>
          </div>
          </div>
          <div className="row">
              <div className="col-md-6">
                  <div className="thumbnail">
                    <img src={promote.images.length == 0 ? no_image : promote.images[0].image} />
                  </div>
              </div>
              <div className="col-md-6">
                  <div className="content">
                      <h4 className="title">{promote.title}</h4>
                      <div className="price">
                          <div>
                              <p className="value">CHF <span>{promote.newPrice}</span></p>
                              <p className="discount">{promote.oldPrice}</p>
                          </div>
                          <p className="percent">-{promote.discount}%</p>
                      </div>
                      <div className="category">
                        <span>{translate('dashboard.promotions.category') }: <a href="#">{promote.categories.length != 0 ? promote.categories[0].name : ""}</a></span>
                      </div>
                      <div className="description">
                          <p>{promote.description}</p>
                      </div>
                      <div className="runtime">
                          <p>{ translate('dashboard.promotions.run_time') }: </p>
                          <div>
                              <span>{ translate('dashboard.promotions.start_date') }</span>
                              <span className="date">{
                                moment(parseInt(promote.startTime)).format('MM.DD.YYYY')
                              }</span>
                          </div>
                          <div>
                              <span>{ translate('dashboard.promotions.end_date') }</span>
                              <span className="date">{
                                moment(parseInt(promote.endTime)).format('MM.DD.YYYY')
                              }</span>
                          </div>
                          <div>
                              <span>{ translate('dashboard.promotions.status') }</span>
                              <span className="date">{
                                this.displayStatus()
                              }</span>
                          </div>
                      </div>
                      <div className="amount">
                          <div>
                              <p>{ translate('dashboard.promotions.piece') }:</p>
                              <span>{promote.limitedItem}/{promote.totalItem}</span>
                          </div>
                          <div>
                              <p>{ translate('dashboard.promotions.visits') }/{ translate('dashboard.promotions.clicks') } :</p>
                              <span>{promote.visits ? promote.visits : 0}/{promote.userClicks.length}</span>
                          </div>
                      </div>
                      <div className="amount">
                          <div>
                              <p>{ translate('dashboard.promotions.user') }:</p>
                              <span>{promote.user && promote.user.fullName}</span>
                          </div>
                          <div>
                              <p>{ translate('dashboard.promotions.email') }:</p>
                              <span>{promote.user && promote.user.email}</span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    )
  }
}

function mapStateToProps({ locale, current_user, loading }){
  return { me: current_user, loading,
          translate: getTranslate(locale) }
}

export default compose(
  graphql(fetchPromote, {
    options: (props) => {  return { variables: { id: props.match.params['promote_id'] } } }
  }),
  graphql(deletePromote),
  connect(mapStateToProps, { })
)(ShowPromote);
