import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const dataSet1 = [
    {
        title: "Promote Title",
        normal_price: 25,
        description: 'Lorem Ipsum',
        offer_price: 19,
        discount: 20,
        category: "Sport",
        start_date: "04/09/2019",
        end_date: "04/25/2019",
        top_seller: true,
        total_item: 100,
        limited_item: 50,
        active: true,
        main_image: "https://www.w3schools.com/w3css/img_lights.jpg"
    },
];


class ExportTemplate extends React.Component {
  render() {
    return (
      <ExcelFile element={
          <a>
            <i class="icon-download"></i>
            <span>Download template</span>
          </a>
        }
        filename="import_template">
        <ExcelSheet data={dataSet1} name="Promotes">
          <ExcelColumn label="Title (*)" value="title"/>
          <ExcelColumn label="Normal Price (*)" value="normal_price"/>
          <ExcelColumn label="Description (*)" value="description"/>
          <ExcelColumn label="Offer Price (*)" value="offer_price"/>
          <ExcelColumn label="Discount (*)" value="discount"/>
          <ExcelColumn label="Category (*)" value="category"/>
          <ExcelColumn label="Start Date (*) (MM/DD/YYYY)" value="start_date"/>
          <ExcelColumn label="End Date (*) (MM/DD/YYYY)" value="end_date"/>
          <ExcelColumn label="TopSeller (*)" value="top_seller"/>
          <ExcelColumn label="Total Item" value="total_item"/>
          <ExcelColumn label="Limited Item" value="limited_item"/>
          <ExcelColumn label="Active (*)" value="active"/>
          <ExcelColumn label="MainImage (*)" value="main_image"/>
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default ExportTemplate
