import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';
import topSellerPromotes
  from '../../queries/promote/topSellerPromotes'

class MostClicks extends React.Component {
  displayList() {
    var { topSellerPromotes, loading } = this.props.data
    var _this = this
    let { translate } = this.props;
    let no_image = "https://cdn11.bigcommerce.com/s-gho61/stencil/31cc7cb0-5035-0136-2287-0242ac11001b/e/3dad8ea0-5035-0136-cda0-0242ac110004/images/no-image.svg"

    if(loading){ return <div /> } 

    return (
      topSellerPromotes.map((promote, idx) => {
        return (
          <Link key={idx}
                 className="promotion"
                to={`/dashboard/promote/${promote.id}`}>
              <div className="item">
                  <div className="thumbnail">
                    <img src={promote.images.length == 0 ? no_image : promote.images[0].image} />
                  </div>
                  <div className="content">
                      <h4 className="title">{promote.title}</h4>
                      <div className="price">
                          <p className="value">CHF <span>{promote.newPrice}</span></p>
                          <p className="discount">{promote.oldPrice}</p>
                      </div>
                  </div>
              </div>
              <div className="sale-number">
                <p>
                  { `${promote.clicks} ${translate('dashboard.dashboard.clicks')}` }
                </p>
                  <span>-{promote.discount}%</span>
              </div>
          </Link>
        )
      })
    )
  }


  render () {
    let { loading } = this.props.data
    let { translate } = this.props;

    if(loading) {
      return <div></div>
    }

    return(
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="box">
              <div className="head">
                  <div className="title">
                    <h5>
                      { translate('dashboard.dashboard.most_clicked_promotions')}
                    </h5>
                  </div>
                  <div className="filter">
                  </div>
              </div>
              <div className="body">
                {this.displayList()}
              </div>
          </div>
      </div>
    )
  }
}

export default compose(
  graphql(topSellerPromotes),
)(MostClicks)
