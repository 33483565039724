import React from 'react';

import { getToken } 
  from '../../services/token'

const requireAuth = (WrappedComponent) => {
  class HOC extends React.Component {
    componentWillMount(){
      if(!getToken){
        this.props.history.push('/auth')
      }
    }

    render() {
      return <WrappedComponent { ...this.props } />;
    }
  }
    
  return HOC;
};

export default requireAuth
