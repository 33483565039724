import { LOG_OUT, CURRENT_USER }
  from '../constants/index'

export function current_user(userArgs) {
  return (dispatch) => {
    dispatch({
      type: CURRENT_USER,
      payload: userArgs
    })
  }
}

