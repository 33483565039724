import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';
import mostClicksPromotes
  from '../../queries/promote/mostClicksPromotes'

class MostClicks extends React.Component {
  displayList() {
    var { mostClicksPromotes } = this.props.data
    let { translate } = this.props; 
    var _this = this

    return (
      mostClicksPromotes.map((promote, idx) => {
        return (
          <Link key={idx} className="promotion"
                to={`/dashboard/promote/${promote.id}`}>
              <div className="item">
                  <div className="thumbnail">
                    <img src={promote.images[0].image} />
                  </div>
                  <div className="content">
                      <h4 className="title">{promote.title}</h4>
                      <div className="price">
                          <p className="value">CHF <span>{promote.newPrice}</span></p>
                          <p className="discount">{promote.oldPrice}</p>
                      </div>
                  </div>
              </div>
              <div className="sale-number">
                <p> 
                  { `${promote.clicks} ${translate('dashboard.dashboard.clicks')}` }
                </p>
                  <span>-{promote.discount}%</span>
              </div>
          </Link>
        )
      })
    )
  }


  render () {
    let { loading } = this.props.data
    let { translate } = this.props; 

    if(loading) {
      return <div></div>
    }

    return(
      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="box">
              <div className="head">
                  <div className="title">
                    <h5>
                      {translate('dashboard.dashboard.most_clicked_promotions')}
                    </h5>
                  </div>
                  <div className="filter">
                  </div>
              </div>
              <div className="body">
                {this.displayList()}
              </div>
          </div>
      </div>
    )
  }
}

export default compose(
  graphql(mostClicksPromotes),
)(MostClicks)
