import React
  from 'react'

import ChangePassword
  from './ChangePassword'

import UpdateUserInfo
  from './UpdateUserInfo'

import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo'

import updateYourDataMutation
  from '../../../mutations/dashboard/settings/updateYourData'

import query
  from '../../../queries/currentUser'

import { connect } 
   from 'react-redux'

import { getTranslate } 
  from 'react-localize-redux';

import { startLoading, moveLoading }
  from '../../../actions/loading'

class Setting extends React.Component {
  defineRoutes() {
    return (
      <div>
        <Route exact path="/dashboard/settings/update"
          render={() => <UpdateUserInfo
                        query={query}
                        me={this.props.me}
                        {...this.props} />}
         />

        <Route exact path="/dashboard/settings/changePassword"
          render={() => <ChangePassword me={this.props.me}
                        query={query}
                        {...this.props} />}
         />
      </div>
    )
  }

  render(){
    let { translate } = this.props

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="menu-settings">
                <ul>
                  <li>
                    <Link
                      className={this.props.location.pathname.indexOf("dashboard/settings/update") > -1 ? 'active' : ''}
                      to={`/dashboard/settings/update`}>
                      { translate('dashboard.settings.personal_info') }
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={this.props.location.pathname.indexOf("dashboard/settings/changePassword") > -1 ? 'active' : ''}
                      to={`/dashboard/settings/changePassword`}>
                      { translate('auth.password') }
                    </Link>
                  </li>
                </ul>
              </div>

            {this.props.me && this.defineRoutes()}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ locale, current_user, loading }){
  return { 
     me: current_user, 
     loading,
     translate: getTranslate(locale) 
  }
}

export default compose(
  graphql(updateYourDataMutation),
  connect(mapStateToProps, { startLoading, moveLoading })
)(Setting);
