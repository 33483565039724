import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";

import UploadImagefields from './UploadImageFields';
import Loader from 'react-loader-spinner'

// import graphql, queries and mutations
import { graphql, compose }
  from 'react-apollo';
import fetchCategories
  from '../../queries/category/fetchCategories';
import fetchPromotions
  from '../../queries/promote/fetchPromotions';
import updatePromote
  from '../../mutations/dashboard/promotes/updatePromote';
import addPromoteImages
  from '../../mutations/dashboard/promotes/addPromoteImages';
import fetchPromote
  from '../../queries/promote/fetchPromote';

import { connect }
   from 'react-redux';

import uploadImage from '../../services/uploadImage';
import { startLoading, moveABitLoading, moveLoading }
  from '../../actions/loading';
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    paddingBottom: 40,
  }),
  control: (base, state) => {
    // none of react-select's styles are passed to <Control />
    return {
      width: "100%",
      marginBottom: 20
    }
  },
  // multiValue: (styles, { data }) => {
  //   return {
  //     ...styles,
  //     backgroundColor: data.labelColor,
  //   };
  // },
  // multiValueLabel: (styles, { data }) => {
  //   console.log()
  //   return {
  //   ...styles,
  //   color: data.textColor,
  //   }
  // },
  // multiValueRemove: (styles, { data }) => {
  //   return {
  //     ...styles,
  //     ':hover': {
  //       backgroundColor: data.color,
  //       color: 'white',
  //     },
  //   }
  // },
}

class NewPromoteInputs extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      language: {value: "en", label:"en"},
      categories: [],
      loading: false,
      mainImage: '',
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      title: '',
      normalPrice: '',
      description: '',
      offerPrice: '',
      discount: '',
      category: '',
      startDate: '',
      endDate: '',
      topSeller: false,
      totalItem: null,
      limitedItem: null,
      active: true,
      onSlider: false,
      errors: 0,
      titleError: '',
      descriptionError: '',
      offerPriceError: '',
      normalPriceError: '',
      categoryError: '',
      discountError: '',
      startDateError: '',
      endDateError: '',
      topSellerError: '',
      activeError: '',
      selectedCategories: [],
      languages: [
        {value: "en", label: "en"},
        {value: "de", label: "de"},
        {value: "fr", label: "fr"},
        {value: "it", label: "it"},
      ]
    }
  }

  componentDidMount() {

    this.setState({categories: this.props.categories})

    if(!this.props.data.loading && this.props.me) {
      if(parseInt(this.props.data.promote.user.id) != parseInt(this.props.me.id)) {
        this.props.history.push(`/dashboard/promotions`)
      }
      if(this.props.data.promote == []) {
        this.props.history.push(`/dashboard/promotions`)
      }
    }
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.data.promote != this.props.data.promote) {
      this.changePromoteState(nextProps)
      if(this.props.me.roleId != 1) {
        if(parseInt(nextProps.data.promote.user.id) != parseInt(this.props.me.id)) {
          this.props.history.push(`/dashboard/promotions`)
        }
      }
    }
  }

  changePromoteState(props){
    let currentCategories = []

    if(props.data.promote) {
      var { promote } = props.data

      promote.categories.map((category, idx) => {
        currentCategories.push({ value: category.id,
                                 label: category.name,
                                 labelColor: idx == 0 ? "#ff0844" : "#e9ecef",
                                 textColor: idx == 0 ? "#ffffff" : "#000000"
                              });
      })

      this.setState({
        title: promote.title,
        description: promote.description,
        normalPrice: promote.oldPrice,
        offerPrice: promote.newPrice,
        discount: promote.discount,
        startDate: moment(parseInt(promote.startTime)).format("YYYY-MM-DD"),
        endDate: moment(parseInt(promote.endTime)).format("YYYY-MM-DD"),
        topSeller: promote.topSeller,
        totalItem: promote.totalItem,
        limitedItem: promote.limitedItem,
        active: promote.active,
        onSlider: promote.onSlider,
        selectedCategories: currentCategories
      })

      this.changePromoteImageState(promote)
    }
  }

  changePromoteImageState(promote) {
    var images = ['mainImage', 'image1', 'image2', 'image3', 'image4']
    promote.images.map((image, index) => {
      this.setState({
        [images[index]]: image.image
      })
    })
  }

  componentDidMount(){
    this.changePromoteState(this.props)
  }

  handleImageChange(event, image) {
    this.setState({
      [image]: event.target.files[0]
    })
  }


  handleChangeItem(state, event, error) {
    this.setState({
      [state]: event.target.value,
      [error]: ''
    })

    if(state == 'offerPrice') {
      this.calculateDiscount(this.state.normalPrice, event.target.value)
    }

    if(state == 'normalPrice') {
      this.calculateDiscount(event.target.value, this.state.offerPrice)
    }
  }

  calculateDiscount(normalPrice, offerPrice) {
    if(normalPrice != '' && offerPrice != '') {
      let res = (offerPrice / normalPrice) * 100
      let total = 100 - res
      if(total < 0) {
        this.setState({discount: 'NaN'})
      } else {
        this.setState({discount: 100 - res})
      }
    } else {
      this.setState({discount: ''})
    }
  }

  handleChangeBoolean(type, state) {
    this.setState({
      [type]: !state
    })
  }

  onChangeCategory(event) {
    this.setState({category: event.target.value});
  }

  submitPromoteForm() {
    let { startLoading,
          moveLoading } = this.props;
    let catSelected = []
    var { title,
          normalPrice,
          description,
          offerPrice,
          normalPrice,
          category,
          discount,
          startDate,
          endDate,
          errors,
          topSeller,
          active,
          onSlider,
          totalItem,
          limitedItem,
          selectedCategories } = this.state
    if(parseInt(offerPrice) > parseInt(normalPrice)) {
      return alert("Offer price is bigger than normal price")
    }

    debugger
    if(this.handleRequireFields() && parseInt(offerPrice) <= parseInt(normalPrice)) {
      this.props.startLoading()
      this.setState({loading: true})

      selectedCategories.map((category, idx) => {
        catSelected.push(parseInt(category.value));
      })

      this.props.mutate({
        mutation: updatePromote,
        variables: { language: this.state.language.value, promoteId: parseInt(this.props.data.promote.id), title,
                     userId: parseInt(this.props.me.id), oldPrice: parseFloat(normalPrice), description,
                     newPrice: parseFloat(offerPrice), discount: parseFloat(discount.toFixed(0)), categoryId: parseInt(category),
                     startTime: Date.parse(startDate).toString(), endTime: Date.parse(endDate).toString(),
                     topSeller, active, onSlider, categories: catSelected, limitedItem: parseInt(limitedItem), totalItem: parseInt(totalItem) },
        refetchQueries: [{ query: fetchPromote, variables: { id: this.props.match.params['promote_id'] } }]
      }).then(res => {
        this.setState({promoteId: res.data.updatePromote.id})
        this.props.moveLoading()
        this.requestImage()
      }).catch(res => {
        const errors = res.graphQLErrors.map((err) => err.message)
        this.props.moveLoading()
        this.setState({loading: false})
        alert(errors[0])
        if(errors[0] == "You can not update this promote, because it is not yours promote !.. Please don try again.") {
          this.props.history.push(`/dashboard/promotions`)
        }
      })
    } else {
      this.props.moveLoading()
    }
  }

  displaySubmitButton() {
    if(this.state.loading) {
      return (
        <div style={{marginLeft: "44%"}}>
          <Loader
            type="Bars"
            color="#ff0844"
            height="50"
          />
        </div>
      )
    } else {
      return(
       <button
        type="button"
        className="btn btn-primary btn-full-width"
        onClick={() => this.submitPromoteForm()} >Add</button>
      )
    }
  }

  displayTopSellerField() {
    if(this.props.me) {
      if(this.props.me.roleId == 1) {
        return (
          <div className="col-md-4 d-flex align-items-center">
            <div className="checkbox">
              <input
                type="checkbox"
                id="datelimited"
                value="Checbox"
                checked={this.state.topSeller ? true : false }
                onChange={(value) => this.handleChangeBoolean('topSeller', this.state.topSeller, 'topSellerError')} />
              <label for="datelimited">TopSeller</label>
            </div>
          </div>
        )
      }
    }
  }

  addLanguageOptions() {
    let languages = ["en", "de", "fr", "it"]
    return (
      languages.map((language) => {
        return (
          <option value={language}>{language}</option>
        )
      })
    )
  }

  onChangeLanguage(event) {

    this.setState({language: event});
    this.changeStateOfPromote(event.value)

  }

  changeStateOfPromote(currentLanguage) {
    let { promote } = this.props.data
    let translationPromote = promote.translations.filter((trans) => trans.language == currentLanguage)

    if(currentLanguage == "en") {
      this.setState({title: promote.title, description: promote.description})
      return
    }

    if(translationPromote.length == 0) {
      this.setState({title: '', description: ''})
    } else {
      this.setState({title: translationPromote[0].title, description: translationPromote[0].description})
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedCategories: selectedOption });
  }

  addCategoriesOptions() {
    let catOptions = []
    let { selectedCategories } = this.state
    let { categories } = this.props

    categories.map((category, idx) => {
      catOptions.push({ value: category.id, label: category.name });
    })

    return (
      <Select
        value={selectedCategories}
        styles={customStyles}
        onChange={this.handleChange}
        options={catOptions}
        isMulti={true}
        isSearchable={true}
        placeholder={" "}
      />
    );
  }

  render () {
    let { loading, promote } = this.props.data
    if(loading) {
      return <div></div>
    }

    return(
      <div className="col-md-10">
        <UploadImagefields
          handleImageChange={this.handleImageChange.bind(this)}
          mainImage={this.state.mainImage}
          image1={this.state.image1}
          image2={this.state.image2}
          image3={this.state.image3}
          image4={this.state.image4} />
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">

              {
                /*<select className="form-control" onChange={this.onChangeLanguage.bind(this)} value={this.state.language}>
                {this.addLanguageOptions()}
              </select>*/
              }
              <label>Choose language</label>
              <Select
                value={this.state.language}
                onChange={this.onChangeLanguage.bind(this)}
                options={this.state.languages}
              />
            </div>
          </div>
          <div className={"col-md-8"}></div>
          <div className="col-md-6">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.titleError}</p>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={this.state.title}
                onChange={(value) => this.handleChangeItem('title', value, 'titleError')} />
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group">
                <label>Select categories</label>
                <p style={{fontSize: 12, color: 'red'}}>{this.state.categoryError}</p>
                {this.addCategoriesOptions()}
            </div>
          </div>
          <div className="col-md-12">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.descriptionError}</p>
            <div className="form-group">
              <label>Description</label>
              <textarea
                rows="5"
                className="form-control"
                value={this.state.description}
                onChange={(value) => this.handleChangeItem('description', value, 'descriptionError')} >
              </textarea>
            </div>
          </div>

          <div className="col-md-4">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.normalPriceError}</p>
            <div className="form-group">
              <label>Normal Price</label>
              <input
                type="text"
                name="normalPrice"
                className="form-control"
                value={this.state.normalPrice}
                onChange={(value) => this.handleChangeItem('normalPrice', value, 'normalPriceError')} />
              <span className="price-percent">CHF</span>
            </div>
          </div>
          <div className="col-md-4">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.offerPriceError}</p>
            <div className="form-group">
              <label>Offer price</label>
              <input
                type="text"
                name="offerprice"
                className="form-control"
                placeholder="Offer price"
                value={this.state.offerPrice}
                onChange={(value) => this.handleChangeItem('offerPrice', value, 'offerPriceError')} />
              <span className="price-percent">CHF</span>
            </div>
          </div>
          <div className="col-md-4">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.discountError}</p>
            <div className="form-group">
              <label>Discount</label>
              <input
                type="text"
                name="percent"
                className="form-control"
                disabled={true}
                value={ this.state.discount != 0 && this.state.discount != '' && this.state.discount != 'NaN' ?
                        this.state.discount.toFixed(2) : 0}
                onChange={(value) => this.handleChangeItem('discount', value, 'discountError')} />
              <span className="price-percent">%</span>
            </div>
          </div>
          <div className="col-md-6">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.startDateError}</p>
            <div className="form-group form-date">
              <label>Start date</label>
              <input
                type="date"
                name="date"
                className="form-control"
                value={this.state.startDate}
                onChange={(value) => this.handleChangeItem('startDate', value, 'startDateError')}
                />
            </div>
          </div>
          <div className="col-md-6">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.endDateError}</p>
            <div className="form-group form-date">
              <label>End date</label>
              <input
                type="date"
                name="date"
                className="form-control"
                value={this.state.endDate}
                onChange={(value) => this.handleChangeItem('endDate', value, 'endDateError')} />
            </div>
          </div>
          {this.displayTopSellerField()}
          <div className="col-md-4">
            <div className="form-group">
              <label>Total Item</label>
              <input
                type="number"
                name="percent"
                className="form-control"
                value={this.state.totalItem}
                onChange={(value) => this.handleChangeItem('totalItem', value, 'totalItemError')} />
              <span className="price-percent"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Limited item</label>
              <input
                type="number"
                name="percent"
                className="form-control"
                value={this.state.limitedItem}
                onChange={(value) => this.handleChangeItem('limitedItem', value, 'limitedItemError')} />
              <span className="price-percent"></span>
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col-md-3">
                <div className="checkbox">
                    <input
                      type="checkbox"
                      id="piecelimited"
                      value="Checbox"
                      checked={this.state.active ? true : false }
                      onChange={(value) => this.handleChangeBoolean('active', this.state.active, 'activeError')}  />
                    <label for="piecelimited">Active</label>
                </div>
            </div>
            <div className="col-md-3">
                <div className="checkbox">
                    <input
                      type="checkbox"
                      id="onSliderId"
                      value="Checbox"
                      checked={this.state.onSlider ? true : false }
                      onChange={(value) => this.handleChangeBoolean('onSlider', this.state.onSlider, 'onSliderError')}  />
                    <label for="onSliderId">On Slider</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                  {this.displaySubmitButton()}
                </div>
            </div>
        </div>
      </div>
    )
  }

  async requestImage() {
    let mainImage = this.state.mainImage
    let image1 = this.state.image1
    let image2 = this.state.image2
    let image3 = this.state.image3
    let image4 = this.state.image4

    if(this.props.data.promote.images[0]) {
      if(this.state.mainImage != this.props.data.promote.images[0].image) {
        mainImage = await uploadImage(this.state.mainImage)
      }
    } else {
      mainImage = await uploadImage(this.state.mainImage)
    }
    if(this.props.data.promote.images[1]) {
      if(this.state.image1 != this.props.data.promote.images[1].image) {
         image1 = this.state.image1 != '' ? await uploadImage(this.state.image1) : ''
      }
    } else {
      image1 = this.state.image1 != '' ? await uploadImage(this.state.image1) : ''
    }
    if(this.props.data.promote.images[2]) {
      if(this.state.image2 != this.props.data.promote.images[2].image) {
         image2 = this.state.image2 != '' ? await uploadImage(this.state.image2) : ''
      }
    } else {
      image2 = this.state.image2 != '' ? await uploadImage(this.state.image2) : ''
    }
    if(this.props.data.promote.images[3]) {
      if(this.state.image3 != this.props.data.promote.images[3].image) {
         image3 = this.state.image3 != '' ? await uploadImage(this.state.image3) : ''
      }
    } else {
      image3 = this.state.image3 != '' ? await uploadImage(this.state.image3) : ''
    }
    if(this.props.data.promote.images[4]) {
      if(this.state.image4 != this.props.data.promote.images[4].image) {
         image4 = this.state.image4 != '' ? await uploadImage(this.state.image4) : ''
      }
    } else {
      image4 = this.state.image4 != '' ? await uploadImage(this.state.image4) : ''
    }

    this.addImages(mainImage, image1, image2, image3, image4)
  }

  addImages(mainImage, image1, image2, image3, image4) {
    this.setState({
      mainImageUpload: mainImage,
      image1Upload: image1,
      image2Upload: image2,
      image3Upload: image3,
      image4Upload: image4
    })
    this.saveToDatabase()
  }

  saveToDatabase() {
    let images = [this.state.mainImageUpload, this.state.image1Upload, this.state.image2Upload, this.state.image3Upload, this.state.image4Upload]
    let imageUpload = images.filter((image) => image != '')

    if(imageUpload != []) {
      this.props.mutate({
        mutation: addPromoteImages,
        variables: { gallery: imageUpload, promoteId: parseInt(this.state.promoteId)},
        refetchQueries: [
          {
            query: fetchPromotions
          },
          {
            query: fetchPromote, variables: { id: parseInt(this.state.promoteId) }
          }
        ]
      }).then(res => {
        this.setState({loading: false})
        this.props.moveLoading()
        this.props.history.push(`/dashboard/promote/${this.state.promoteId}`)
      }).catch(res => {
        const errors = res.graphQLErrors.map((err) => err.message)
        alert(errors[0])
      })
    } else {
      this.props.history.push(`/dashboard/promote/${this.state.promoteId}`)
    }
  }

  handleRequireFields() {
    var { mainImage, title, normalPrice, description, offerPrice, selectedCategories, discount, startDate, endDate, errors} = this.state

    if(!mainImage) {
      alert("Main image can not be blank")
      return 0
    }

    Object.keys(title.split(' ').join('')).length == 0 ? this.setState({titleError: 'Title should not be blank !..', errors: errors + 1}) : this.setState({titleError: '', errors: errors == 0 ? 0 : errors - 1})
    normalPrice == '' ? this.setState({normalPriceError: 'Normal price should not be blank !..', errors: errors + 1}) : this.setState({normalPriceError: '', errors: errors == 0 ? 0 : errors - 1})
    Object.keys(description.split(' ').join('')).length == 0 ? this.setState({descriptionError: 'Description should not be blank !..', errors: errors + 1}) : this.setState({descriptionError: '', errors: errors == 0 ? 0 : errors - 1})
    offerPrice == '' ? this.setState({offerPriceError: 'Offer price should not be blank !..', errors: errors + 1}) : this.setState({offerPriceError: '', errors: errors == 0 ? 0 : errors - 1})
    normalPrice == '' ? this.setState({normalPriceError: 'Normal price should not be blank !..', errors: errors + 1}) : this.setState({normalPriceError: '', errors: errors == 0 ? 0 : errors - 1})
    discount == '' ? this.setState({discountError: 'Discount should not be blank !..', errors: errors + 1}) : this.setState({discountError: '', errors: errors == 0 ? 0 : errors - 1})
    selectedCategories.length == 0 ? this.setState({categoryError: 'Category should not be blank !..', errors: errors + 1}) : this.setState({categoryError: '', errors: errors == 0 ? 0 : errors - 1})
    startDate == "Invalid date" || startDate == "" ? this.setState({startDateError: 'Start date should not be blank !..', errors: errors + 1}) : this.setState({startDateError: '', errors: errors == 0 ? 0 : errors - 1})
    endDate == "Invalid date" || endDate == "" ? this.setState({endDateError: 'End date should not be blank !..', errors: errors + 1}) : this.setState({endDateError: '', errors: errors == 0 ? 0 : errors - 1})

    var { titleError, normalPriceError, descriptionError, offerPriceError, normalPriceError, discountError, categoryError, startDateError, endDateError} = this.state
    return Object.keys(title.split(' ').join('')).length != 0 && normalPrice != ''
            && Object.keys(description.split(' ').join('')).length != 0 && offerPrice != ''
            && normalPrice != '' && discount != ''
            && selectedCategories.length != 0 && (startDate != "Invalid date" || startDate != "")
            && (endDate != "Invalid date" || endDate != "")

  }
}

function mapStateToProps({ current_user, loading }){
  return { me: current_user, loading }
}

export default compose(
  graphql(fetchPromote, {
    options: (props) => {  return { variables: { id: props.match.params['promote_id'] } } }
  }),
  graphql(addPromoteImages),
  connect(mapStateToProps, { startLoading, moveABitLoading, moveLoading })
)(NewPromoteInputs)
