import gql from 'graphql-tag';

export default gql`
  query promotesWithUserId($userId: Int, $typeId: ID!, $sortBy: String!, $active: Boolean!) {
    promotesWithUserId(userId: $userId,typeId: $typeId,sortBy: $sortBy,active: $active) {
      id
      title
      oldPrice
      newPrice
      discount
      createdAt
      clicks
      active
    }
  }
`;
