import gql from 'graphql-tag'

export default gql`
  mutation ImportPromote($title: String!,
                         $userId: Int!,
                         $oldPrice: Float!,
                         $description: String!,
                         $newPrice: Float!,
                         $discount: Float!,
  								       $categoryName: String!,
                         $startTime: String!,
                         $endTime: String!,
                         $topSeller: Boolean!,
                         $active: Boolean!,
                         $mainImage: String!){
    importPromote(title:$title,
                  userId:$userId,
                  oldPrice:$oldPrice,
                  description:$description,
                  newPrice:$newPrice,
                  discount:$discount,
                  categoryName:$categoryName,
                  startTime:$startTime,
                  endTime:$endTime,
                  topSeller:$topSeller,
                  active:$active,
                  mainImage:$mainImage) {
          id
          title
          oldPrice
          newPrice
          discount
          description
          images {
            image
          }
          user {
            id
            email
            image
            webUrl
            username
          }
          category {
            id
            name
          }
          topSeller
          startTime
          endTime
          limitedItem
          active
          totalItem
          averageRating
          totlaRating
    }
  }
`
