import gql from 'graphql-tag';

export default gql`
  query PromoteQuery($id: ID!) {
    promote(id: $id) {
      id
      title
      oldPrice
      newPrice
      discount
      description
      images {
        image
      }
      user {
        id
        email
        image
        fullName
        webUrl
        username
      }
      category {
        id
        name
      }
      categories {
        id
        name
      }
      topSeller
      startTime
      endTime
      limitedItem
      active
      onSlider
      totalItem
      averageRating
      totlaRating
      clicks
      visits
      translations {
        language
        title
        description
      }
      userClicks {
        id
      }
      ratings {
        id
        rate
        comment
        user {
          id
          fullName
        }
        createdAt
      }
    }
  }
`;
