import React from 'react';
import moment from 'moment';

import fetchUser
  from '../../../queries/user/singleUser'

import { graphql, compose }
  from 'react-apollo';

import { Route, Link }
  from "react-router-dom";

import fetchClients
  from '../../../queries/user/fetchClients';

import editUser
  from '../../../mutations/users/editUser'

import { startLoading, moveABitLoading, moveLoading }
  from '../../../actions/loading';

import uploadImage
  from '../../../services/uploadImage';
import addUserImage
  from '../../../mutations/users/addUserImage'
import fetchSuppliers
  from '../../../queries/user/fetchSuppliers';

import { connect }
  from 'react-redux'

class EditSupplier extends React.Component {
  state = {
    file: ''
  }

  componentWillReceiveProps(newProps){
    this.changeUserState(newProps)
  }

  changeUserState(props){
    if(!this.state.user && !props.data.loading){
      var { user } = props.data
      this.setState({
        ...user
      })
    }
  }

  componentDidMount(){
    this.changeUserState(this.props)
  }

  editUser(){
    let { startLoading, moveLoading, query  } = this.props;
    this.setState({ errors: [] })

    startLoading()

    this.props.mutate({
      mutation: editUser,
      variables: { ...this.state },
    }).then(res => {
      moveABitLoading()
      if(this.state.file != '' && this.state.file != this.props.data.user.image) {
        this.uploadUserImage(res.data.editUser.id)
      } else {
        moveLoading()
        this.props.history.push(`/dashboard/suppliers/${this.props.match.params['user_id']}`)
      }
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({success_message: null, errors: errors[0]})
      moveLoading()
    })
  }

  async uploadUserImage(supplierId) {
    let image = await uploadImage(this.state.file)
    this.props.mutate({
      mutation: addUserImage,
      variables: { image: image, userId: parseInt(supplierId) },
      refetchQueries: [{ query: fetchSuppliers }]
    }).then(res => {
      this.props.moveLoading()
      this.props.history.push(`/dashboard/suppliers/${this.props.match.params['user_id']}`)
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({success_message: null, errors: errors[0]})
      this.props.moveLoading()
    })
  }

  handleImageChange(event) {
    if(event.target.files[0] != undefined) {
      this.setState({
        file: event.target.files[0],
      })
    } else {
      this.setState({
        file: '',
      })
    }
  }

  displayImage(file) {
    if(this.props.data.user.image && file == '') {
      return (
        <img
          src={this.props.data.user.image}
          style={{width: 176, height: 176, borderRadius: 90}}
        />
      )
    }
    else if(file){
      return (
        <img
          src={URL.createObjectURL(file)}
          style={{width: 176, height: 176, borderRadius: 90}}
        />
      )
    } else {
      return (
        <div className="drag-text">
          <span>Upload Image</span>
        </div>
      )
    }
  }

  changePaymentMehtod(e) {
    this.setState({paymentMethod: e.target.value});
  }

  render() {
    if(this.props.data.loading) { return <div /> }
    let { fullName,
      email,
      phone,
      street,
      number,
      zip,
      city,
      webUrl,
      description,
      topSeller
    } = this.state;

    debugger;
    return(
      <div className="container page">
        <div className="row">
          <div className="col-md-12">
            <div className="page-head">
              <div className="page-title">
                <h5>Edit Supplier</h5>
              </div>
              <div className="right-info">
                <div className="close-button">
                  <Link to={`/dashboard/suppliers/${this.props.match.params['user_id']}`} >
                    <span>Close</span>
                    <i className="icon-close"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8 col-xl-8">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <div className="file-upload">
                  <div className="image-upload-wrap">
                    <input
                      type="file"
                      className="file-upload-input"
                      accept="image/*"
                      onChange={(e) => this.handleImageChange(e)} />
                      {this.displayImage(this.state.file)}
                  </div>
                </div>
              </div>

              <p style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>

              <div className="col-md-6">
                <div className="form-group">
                  <input type="text"
                    className="form-control"
                    placeholder="Name"
                    value={fullName}
                    onChange={(res) => {this.setState({ fullName: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(res) => {this.setState({ email: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text"
                    className="form-control"
                    placeholder="Phone"
                    value={phone}
                    onChange={(res) => {this.setState({ phone: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text"
                    className="form-control"
                    placeholder="Street"
                    value={street}
                    onChange={(res) => {this.setState({ street: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text"
                    className="form-control"
                    placeholder="Nr"
                    value={number}
                    onChange={(res) => {this.setState({ number: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text"
                    className="form-control"
                    placeholder="zip"
                    value={zip}
                    onChange={(res) => {this.setState({ zip: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text"
                    className="form-control"
                    value={city}
                    onChange={(res) => {this.setState({ city: res.target.value })} }
                    placeholder="City" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="select-custom select-full-width">
                  <select className="form-control" onChange={this.changePaymentMehtod.bind(this)} value={this.state.paymentMethod}>
                    <option selected="" disabled="" value="">Type of Payment</option>
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group form-date">
                  <input type="date" name="date" className="form-control" placeholder="Month" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="datelimited"
                    value="Checbox"
                    checked={topSeller ? true : false }
                    onChange={(value) => this.setState({ topSeller: topSeller == null ? true : !topSeller })} />
                  <label for="datelimited">TopSeller</label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <textarea rows="5"
                    className="form-control"
                    value={description}
                    onChange={(res) => {this.setState({ description: res.target.value })} }
                    placeholder="Description"></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <input type="text"
                    className="form-control"
                    placeholder="Website"
                    value={webUrl}
                    onChange={(res) => {this.setState({ webUrl: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <button type="button"
                    className="btn btn-primary btn-full-width"
                    onClick={() => { this.editUser() }} >Update</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(editUser),
  graphql(addUserImage),
  graphql(fetchUser, {
    options: (props) => {  return { variables: { id: props.match.params['user_id'] } } }
  }),
  connect(null, { startLoading, moveLoading })
)(EditSupplier);
