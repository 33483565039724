import React from 'react';

import { graphql, compose }
  from 'react-apollo';
import fetchCategories
  from '../../../queries/category/fetchCategories';

import createCategory
  from '../../../mutations/dashboard/categories/createCategory';

import { connect }
  from 'react-redux'

import { getTranslate }
  from 'react-localize-redux';

import LIMIT
  from '../../../constants/pagination_limit';

class NewCategory extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      name: '',
      error: '',
      language: 'en'
    }
  }

  requestCreateCategory() {
    var { name } = this.state

    let { translate } = this.props

    if(name == '') {
      this.setState({error: translate('dashboard.category.name_empty')})
      setTimeout( () => {
        this.setState({error: ''})
        },
      2000);
      return
    }

    this.props.mutate({
      variables: { name: name, userId: parseInt(this.props.me.id) },
      refetchQueries: [{ query: fetchCategories, variables: { offset: 0, limit: LIMIT } }]
    }).then(res => {
      alert(translate('dashboard.category.category_created'))
      this.props.history.goBack()
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({error: errors[0]})
      setTimeout( () => {
        this.setState({errors: ''})
        },
      3000);
    })
  }

  render () {
    let { translate } = this.props
    return(
      <div>
        <div className="container page">
            <div className="row">
                <div className="col-md-12">
                    <div className="page-head">
                        <div className="left-info">
                            <div className="back">
                                <button
                                  onClick={() => this.props.history.goBack()}
                                  type="button">
                                    <i className="icon-arrow-left"></i>
                                    <span>
                                      { translate('dashboard.actions.back')}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="page-title">
                          <h5>
                            { translate('dashboard.category.create_category')}
                          </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-8">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                          <p style={{color: 'red', marginBottom: 10}}>{this.state.error}</p>
                          <div className="form-group">
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              value={this.state.name}
                              onChange={(e) => this.setState({name: e.target.value})} />
                          </div>
                        </div>
                        <div className="col-md-6 justify-content-end">
                            <div className="form-group">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-full-width"
                                  onClick={() => this.requestCreateCategory()}>Create</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ locale, current_user }){
  return { me: current_user,
     translate: getTranslate(locale) }
}

export default compose(
  graphql(createCategory),
  connect(mapStateToProps, {})
)(NewCategory)
