import React from 'react';
import moment from 'moment';

import fetchUser
  from '../../../queries/user/singleUser'

import { graphql, compose }
  from 'react-apollo';

import { Route, Link }
  from "react-router-dom";

import fetchClients
  from '../../../queries/user/fetchClients';
import fetchCategories
  from '../../../queries/category/fetchCategories';

import addNewSupplier
  from '../../../mutations/users/addNewSupplier'

import { startLoading, moveABitLoading, moveLoading }
  from '../../../actions/loading'

import { connect }
  from 'react-redux'

import fetchSuppliers
  from '../../../queries/user/fetchSuppliers';
import uploadImage
  from '../../../services/uploadImage';

import LIMIT
  from '../../../constants/pagination_limit';

class NewSupplier extends React.Component {
  state = {
    fullName: '',
    email: '',
    phone: '',
    street: '',
    number: '',
    zip: '',
    city: '',
    webUrl: '',
    description: '',
    category: '',
    image: '',
    file: '',
    paymentMethod: ''
  }

  async addNewSupplier(){
    let { startLoading, moveLoading  } = this.props;
    this.setState({ errors: [] })
    startLoading()

    let image;
    if(this.state.file != '') {
      image = await uploadImage(this.state.file)
    }
    this.props.mutate({
      variables: { ...this.state, image: image },
      refetchQueries: [{ query: fetchSuppliers,
        variables: { order: 'createdAt', offset: 0, limit: LIMIT  }
      }]
    }).then(res => {
      moveLoading()
      this.props.history.push('/dashboard/suppliers')
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({success_message: null, errors: errors[0]})
      moveLoading()
    })
  }

  handleImageChange(event) {
    if(event.target.files[0] != undefined) {
      this.setState({
        file: event.target.files[0],
      })
    } else {
      this.setState({
        file: '',
      })
    }
  }

  displayImage(file) {
    if(file){
      return (
        <img
          src={URL.createObjectURL(file)}
          style={{width: 176, height: 176, borderRadius: 90}}
        />
      )
    } else {
      return (
        <div className="drag-text">
          <span>Upload Image</span>
        </div>
      )
    }
  }

  onChangeCategory(event) {
    this.setState({category: event.target.value});
  }

  addCategoriesOptions() {
    let { categories } = this.props.data

    if(categories){
      return (
        categories.map((category) => {
          return (
            <option value={category.id}>{category.name}</option>
          )
        })
      )
    }
  }

  changePaymentMehtod(e) {
    this.setState({paymentMethod: e.target.value});
  }

  render() {
    let { fullName,
      email,
      phone,
      street,
      number,
      zip,
      city,
      webUrl,
      description,
      file
    } = this.state;

    return(
      <div className="container page">
        <div className="row">
          <div className="col-md-12">
            <div className="page-head">
              <div className="page-title">
                <h5>Add new Supplier</h5>
              </div>
              <div className="right-info">
                <div className="close-button">
                  <Link to='/dashboard/suppliers' >
                    <span>Close</span>
                    <i className="icon-close"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12 col-lg-8 col-xl-8">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                <div className="file-upload">
                  <div className="image-upload-wrap">
                    <input
                      type="file"
                      className="file-upload-input"
                      accept="image/*"
                      onChange={(e) => this.handleImageChange(e)} />
                      {this.displayImage(this.state.file)}
                  </div>
                </div>
              </div>

             <p style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Name</label>
                  <input type="text"
                    className="form-control"
                    value={fullName}
                    onChange={(res) => {this.setState({ fullName: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <input type="email"
                    className="form-control"
                    value={email}
                    onChange={(res) => {this.setState({ email: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Phone</label>
                  <input type="text"
                    className="form-control"
                    value={phone}
                    onChange={(res) => {this.setState({ phone: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Street</label>
                  <input type="text"
                    className="form-control"
                    value={street}
                    onChange={(res) => {this.setState({ street: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Nr</label>
                  <input type="text"
                    className="form-control"
                    value={number}
                    onChange={(res) => {this.setState({ number: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Zip</label>
                  <input type="text"
                    className="form-control"
                    value={zip}
                    onChange={(res) => {this.setState({ zip: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>City</label>
                  <input type="text" name="city" className="form-control"/>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group select-custom select-full-width">
                  <label>Type of Payment</label>
                  <select className="form-control" onChange={this.changePaymentMehtod.bind(this)} value={this.state.paymentMethod}>
                    <option selected="" disabled="" value="">Choose</option>
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                  </select>
                </div>
              </div>
              {/*<div className="col-md-6">
                <div className="select-custom select-full-width">
                  <select className="form-control" onChange={this.onChangeCategory.bind(this)} value={this.state.category}>
                    <option selected="" disabled="" value={''}>Category</option>
                    {this.addCategoriesOptions()}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <div className="select-custom select-full-width">
                  <select className="form-control">
                    <option selected="" disabled="">Type of Payment</option>
                    <option>Payment 1</option>
                    <option>Payment 2</option>
                  </select>
                </div>
              </div>
              */}
              <div className="col-md-12">
                <div className="form-group">
                  <label>Description</label>
                  <textarea rows="5"
                    className="form-control"
                    value={description}
                    onChange={(res) => {this.setState({ description: res.target.value })} }
                    ></textarea>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Website</label>
                  <input type="text"
                    className="form-control"
                    value={webUrl}
                    onChange={(res) => {this.setState({ webUrl: res.target.value })} }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <button type="button"
                    className="btn btn-primary btn-full-width"
                    onClick={() => { this.addNewSupplier() }} >Add</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default compose(
  graphql(addNewSupplier),
  connect(null, { startLoading, moveABitLoading, moveLoading })
)(NewSupplier);
