import gql from 'graphql-tag';

export default gql`
  query UserWithPasswordResetToken($passwordToken: String!) {
    userWithPasswordResetToken(passwordToken: $passwordToken) {
      id
      email
    }
  }
`;
