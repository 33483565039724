import React
  from 'react'

import { Link }
  from "react-router-dom";

import FavIvonLogo
  from '../../assets/images/logo_favicon/logo.svg'

import loginMutation
  from '../../mutations/auth/login'

import { graphql }
  from 'react-apollo'

import { storeToken }
  from '../../services/token'

class Login extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      email: '',
      password: '',
      loading: false,
      errors: []
    }
  }

  submitLogin(e) {
    e.preventDefault();
    let { email, password, errors } = this.state

    if(email && password){
      this.handleLogin(email, password)
    }
  }

  handleLogin(email, password) {
    this.setState({ loading: true, errors: [] })

    this.props.mutate({
      variables: { email, password, provider: 'web' },
    }).then(res => {
      storeToken(res.data.login.token)

      window.location.href = '/dashboard/statistics';
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({ errors, loading: false })
    })
  }

  render(){
    return(
      <div className="content">
        <div className="logo">
          <img alt="" src={FavIvonLogo} />
        </div>
        <div className="description">
          <h3>Login</h3>
          <p>Welcome back, Please log in to your account.</p>
        </div>
        <form>
          <div className="form-group">
            <p style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>
            <input type="email"
              value={this.state.email}
              className="form-control"
              placeholder="Email"
              onChange={(res) => this.setState({ email: res.target.value })} />
          </div>
          <div className="form-group">
            <input type="password"
                   value={this.state.password}
                   className="form-control"
                   placeholder="Password"
                   onChange={(res) => this.setState({ password: res.target.value })} />
          </div>
          <div className="form-group">
            <button type="submit"
              className="btn btn-primary btn-full-width"
              onClick={this.submitLogin.bind(this)} >Login</button>
          </div>
          <div className="form-group d-flex alig-items-center justify-content-center">
            <Link to={`/auth/forgetpassword`}>Forgot Password?</Link>
          </div>
        </form>
      </div>
    )
  }
}

export default graphql(loginMutation)(Login);
