import { createStore, applyMiddleware, compose } from 'redux';
import reducers from '../reducers';
import reduxThunk from 'redux-thunk'
import devToolsEnhancer from 'remote-redux-devtools';

const createStoreWithMiddleware =  compose(applyMiddleware(reduxThunk))(createStore);
const store = createStoreWithMiddleware(reducers, devToolsEnhancer());

export default store;

