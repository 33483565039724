import gql from 'graphql-tag';

export default gql`
  query {
    mostClicksPromotes {
      id
      title
      oldPrice
      newPrice
      discount
      images {
        image
      }
      clicks
    }
  }
`;
