import React from 'react';
import moment from 'moment';

import { graphql, compose }
  from 'react-apollo';
import fetchPromote
  from '../../queries/promote/fetchPromote';
import searchPromotes
  from '../../queries/promote/searchPromotes';
import { Route, Link }
  from "react-router-dom";
import { connect }
   from 'react-redux';

import LogoSmall
  from '../../assets/images/logo_favicon/logo-small.svg';
import ShareScreenBg
  from '../../assets/images/backgrounds/sharescreen.jpg';
import SupplierIcon
  from '../../assets/images/images/supplier-icon.png';

import LogoBig
  from '../../assets/images/logo_favicon/logo-big.svg';
import Favourite
  from '../../assets/images/logo_favicon/favourite.svg';


import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Carousel } from 'react-responsive-carousel';
import Countdown from 'react-countdown-now';

const SharescreenBody = "sharescreen-body";

const Completionist = () => <span>Finished offer!</span>;

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
};

class ShowGuestPromote extends React.Component {
  componentDidMount() {
      document.body.classList.add(SharescreenBody);
  }

  displayImages() {
    let { promote } = this.props.data
    return (
      promote.images.map((image) => {
        return (
          <div>
            <img src={image.image} />
          </div>
        )
      })
    )
  }

  displayRatings() {
    let { promote } = this.props.data
    return (
      promote.ratings.map((rating) => {
        let rateStar = (rating.rate / 5) * 100
        return (
          <li>
            <div className="description">
                <span className="date">{moment(parseInt(promote.startTime)).format('DD MMMM YYYY')}</span>
                <p className="name">{rating.user.fullName}</p>
                <p>{rating.comment}</p>
            </div>
            <div className="star">
              <div className="rating-star">
                  <span style={{ width: `${rateStar}%` }}></span>
              </div>
            </div>
          </li>
        )
      })
    )
  }

  render() {
    let { loading, promote } = this.props.data

    if(loading) {
      return <div></div>
    }
    console.log("promote", promote)
    return(
      <div className="sharescreen">
          <div className="sharescreen-header">
              <div className="sharescreen-logo">
                  <img src={LogoSmall} />
              </div>
              <div className="sharescreen-buttons">
                  <a href="https://salebuddy.ch/" className="get-app">Get app</a>
                  <a href="https://app.salebuddy.ch/auth" className="btn-login">Login</a>
              </div>
          </div>
          <div className="sharescreen-carousel">
              <div className="carousel-salebuddy">
                  <p className="percent">-{promote.discount}%</p>
                  <Carousel autoPlay={false} showArrows={false} showIndicators={false} showStatus={false}>
                    {this.displayImages()}
                  </Carousel>
              </div>
              <div className="sharescreen-content">
                  <div className="sharescreen-content-head">
                    <h1 className="title">{promote.title}</h1>
                  </div>
                  <div className="sharescreen-content-body">
                      <div className="category-seller">
                          <div>
                            {promote.topSeller && <p className="top-seller">Top Seller</p>}
                            <p className="category">{promote.categories[0].name}</p>
                          </div>
                          <div className="price-discount">
                              <p className="price">${promote.newPrice.toFixed(2)}</p>
                              <p className="discount">${promote.oldPrice.toFixed(2)}</p>
                          </div>
                      </div>
                      <div className="offer-pieces">
                           <a href="#" className="offer-btn">
                              <span>Offer ends in</span>

                              <Countdown
                                date={parseInt(promote.endTime)}
                                renderer={renderer}
                              />
                           </a>
                           <a href="#" className="pieces-btn">
                              <span>Pieces limited</span>
                              <span>{promote.limitedItem} / {promote.totalItem}</span>
                           </a>
                      </div>
                      <div className="description">
                        <p>{promote.description}</p>
                      </div>
                      <div className="btn-full-width">
                        <a href={promote.user.webUrl}>Open offer</a>
                      </div>
                      <div className="supplier-details">
                          <div className="supplier-icon">
                              <img src={promote.user.image} />
                          </div>
                          <div className="supplier-description">
                              <p>{promote.user.fullName}</p>
                              <a href="#">View supplier details</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="sharescreen-reviews">
              <div className="ratings">
                  <p className="rating-number">{promote.averageRating.toFixed(1)}<span>/5</span></p>
                  <div className="rating-star">
                      <span style={{ width: `${(promote.averageRating / 5) * 100}%` }}></span>
                  </div>
              </div>
              <div className="reviews">
                  <div className="title">
                      <h4>Reviews</h4>
                  </div>
                  <ul className="reviews-row">
                    {this.displayRatings()}
                  </ul>
              </div>
          </div>
          <div className="sharescreen-footer">
              <div className="logo">
                  <img src={LogoBig} />
                  <p>Alle Angebote an einem Ort</p>
              </div>
              <div className="menu">
                <h4>Information</h4>
                <ul>
                  <li>
                      <a href="https://salebuddy.ch/haufig-gestellte-fragen/">Häufig gestellte Fragen</a>
                  </li>
                  <li>
                      <a href="https://salebuddy.ch/allgemeine-geschaftsbedingungen/">Allgemeine Geschäftsbedingungen</a>
                  </li>
                  <li>
                      <a href="https://salebuddy.ch/datenschutzerklaerung/">Datenschutzerklärung</a>
                  </li>
                </ul>
              </div>
              <div className="app">
                <h4>App</h4>
                <ul>
                  <li>
                      <a href="https://apps.apple.com/de/app/salesbuddy/id1466941635">App store</a>
                  </li>
                  <li>
                      <a href="https://play.google.com/store/apps/details?id=ch.refive.salebuddy">Google play store</a>
                  </li>
                </ul>
              </div>
              <div className="social">
                <h4>Social</h4>
                <ul>
                  <li>
                      <a href="https://www.facebook.com/Salebuddy-198447731102174/">Fb</a>
                  </li>
                  <li>
                      <a href="https://www.instagram.com/salebuddy.ch/">Insta</a>
                  </li>
                </ul>
              </div>
              <div className="info">
                  <div className="copyright">
                      <p>Copyright © 2018. <a href="https://salebuddy.ch/">Salebuddy</a>. Alle Rechte vorbehalten.</p>
                  </div>
                  <div className="info-right">
                      <a href="/impressum">Impressum</a>
                      <p>Made with <img src={Favourite} /> by <a href="https://www.apper.ch/">Apper</a></p>
                  </div>
              </div>
          </div>
        </div>
    );
  }
}

export default compose(
  graphql(fetchPromote, {
    options: (props) => {  return { variables: { id: props.match.params['promote_id'] } } }
  }),
)(ShowGuestPromote);
