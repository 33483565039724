import {
  LOADING
} from '../constants/index'

export function startLoading() {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      payload: 30,
    })
  }
}

export function moveABitLoading() {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      payload: 60,
    })
  }
}

export function moveLoading() {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      payload: 100,
    })
  }
}

export function finishLoading() {
  return (dispatch) => {
    dispatch({
      type: LOADING,
      payload: 0,
    })
  }
}
