import React 
  from 'react'

class ChangePassword extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      currentPassword: '',
      password: '',
      confirmPassword: '',
      errors: []
    }
  }
  
  onSubmit() {
    let { currentPassword, password, confirmPassword } = this.state;
    let { startLoading, moveLoading, query, translate } = this.props;

    this.setState({ errors: [] })
    startLoading()
    
    if(!currentPassword){
      this.setState({ errors: [translate('dashboard.settings.fill_current_password')], 
        success_message: null })
    }else if(password && password != confirmPassword){
      this.setState({ errors: [translate('dashboard.settings.passwords_not_match')], 
        success_message: null })
    }else{
      this.props.mutate({
        variables: this.state,
        refetchQueries: [{ query }]
      }).then(res => {
        this.setState({ success_message: translate('dashboard.settings.updated_succesfully')});
      }).catch(res => {
        const errors = res.graphQLErrors.map((err) => err.message)
        this.setState({ errors, success_message: null })
      })
    }

    moveLoading()
  }
  
  render(){
    let { translate } = this.props
    return(
      <div className="content-settings">
       { this.state.success_message ?
        <p style={{color: 'green', marginBottom: 10}}>{this.state.success_message}</p>
       :
        <p style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>
       }
      
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.settings.old_password')}
              </label>
              <input type="password"
                     value={this.state.currentPassword}
                     className="form-control"
                     onChange={(res) => this.setState({ currentPassword: res.target.value })} />
              
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.settings.new_password')}
              </label>
              <input type="password"
                     value={this.state.password}
                     className="form-control"
                     onChange={(res) => this.setState({ password: res.target.value })} />

            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.settings.confirm_password')}
              </label>
              <input type="password"
                     value={this.state.confirmPassword}
                     className="form-control"
                     onChange={(res) => this.setState({ confirmPassword: res.target.value })} />

            </div>
          </div>
          <div className="col-md-6 col-md-6 d-flex align-items-end">
            <div className="form-group button-items">
              <button type="button"
                className="btn btn-primary btn-full-width"
                onClick={() => this.onSubmit()} >
                {translate('dashboard.actions.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangePassword

