import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';
import { connect }
  from 'react-redux';
import FavIvonLogo1
  from '../../assets/images/images/jacket.jpg';
import searchSupplierPromotes
  from '../../queries/promote/searchSupplierPromotes';

class SupplierPromotionsList extends React.Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.searchItem !== this.props.searchItem || nextProps.typeId !== this.props.typeId) {
      this.handleSearchItem(nextProps.searchItem, nextProps.typeId)
    }
  }

  handleSearchItem(search, typeId) {
    const { refetch } = this.props.data
    refetch({ search: search != "" ? search : "", typeId: typeId, sortBy: 'createdAt'})
  }

  displayList() {
    var _this = this
    var { searchPromotes } = this.props.data
    let { translate } = this.props

    if(searchPromotes != 0) {
      return (
        searchPromotes && searchPromotes.map((promote, idx) => {
          return (
            <Link
              key={idx} className="col-md-4 col-lg-3 col-xl-3"
              to={`/dashboard/promote/${promote.id}`}>
              <div className="promotion-item">
                <div className="thumbnail">
                  <img src={promote.images[0].image} />
                </div>
                <div className="content">
                  <h4 className="title">{promote.title}</h4>
                  <div className="price">
                    <div>
                      <p className="value">CHF <span>{promote.newPrice}</span></p>
                      <p className="discount">{promote.oldPrice}</p>
                    </div>
                    <p className="percent">-{promote.discount}%</p>
                  </div>
                  <div className="category">
                    <a href="#">{promote.category.name}</a>
                  </div>
                </div>
                <div className="more-details">
                  <Link
                    to={`/dashboard/promote/${promote.id}`}>
                    <span>
                      { translate('dashboard.promotions.view_more_details') }
                    </span>
                    <i className="icon-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </Link>
          )
        })
      )
    } else {
      return (
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="promotion-item" style={{marginTop: 50}}>
            <p>
              { translate('dashboard.promotions.not_found_any_promotion') }
              {this.props.searchItem ? ` ${ translate('dashboard.promotions.with') } '${this.props.searchItem}'` : ''}
            </p>
          </div>
        </div>
      )
    }
  }

  render () {
    let { loading, searchPromotes } = this.props.data

    if(loading) {
      return <div></div>
    }
    return(
      <div className="container">
        <div className="row">
          {this.displayList()}
        </div>
        {/*
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              <ul className="pagination">
                <li className="page-item">
                  <a className="page-link" href="#"><i className="icon-arrow-left"></i></a>
                </li>
                <li className="page-item">
                  <a className="page-link active" href="#">1</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">2</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#">3</a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#"><i className="icon-arrow-right"></i></a>
                </li>
              </ul>
            </div>
          </div>
        */}
      </div>
    )
  }
}

export default compose(
  graphql(searchSupplierPromotes, {
    options: (props) => {  return { variables: { userId: parseInt(props.userId), search: props.searchItem, typeId: props.typeId, sortBy: props.sortBy} } }
  }),
)(SupplierPromotionsList)
