import React, { Component }
  from 'react';

import { ApolloProvider }
  from 'react-apollo'

import   ApolloClient
  from 'apollo-client';

import { Provider }
  from 'react-redux';
import store
  from './store';

import { InMemoryCache }
  from 'apollo-cache-inmemory'
import { createHttpLink }
  from 'apollo-link-http';
import { setContext }
  from 'apollo-link-context';

import { BASE_URL }
  from './constants'

import { BrowserRouter as Router, Route }
  from "react-router-dom";

import Home
  from './pages/home'

import Auth
  from './pages/auth'

import Guest
  from './pages/guest'

import Dashboard
  from './pages/dashboard'

import requireAuth
  from './components/highOrder/RequireAuth'

import Authorization
  from './components/highOrder/Authorization'

import { getToken }
  from './services/token'

import { initialize, addTranslation }
  from 'react-localize-redux';

import translations
  from './translation/translations.json'

const cache = new InMemoryCache({
  dataIdFromObject: object => object.id
})

const httpLink = createHttpLink({
  uri: BASE_URL,
});

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: getToken ||  "",
    }
  }
});

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
});

class App extends Component {
  render() {
    if( store ) {
      const languages = ['en', 'de', 'it', 'fr'];
      let defaultLanguage = localStorage.getItem('language')

      store.dispatch(initialize(languages, { defaultLanguage: (defaultLanguage || 'en') }));
      store.dispatch(addTranslation(translations));
    }

    return (
      <ApolloProvider client={client}>
         <Provider store={ store }>
           <Router>
            <div className="App">
              <Route exact path="/" component={Home} />
              <Route path="/guest" component={Guest} />
              <Route path="/auth" component={Authorization(Auth)} />
              <Route path="/dashboard" component={requireAuth(Dashboard)} />
            </div>
          </Router>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
