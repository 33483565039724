import React from 'react';

import { getToken } 
  from '../../services/token'

const Authorization = (WrappedComponent) => {
  class HOC extends React.Component {
    componentWillMount(){
      if(getToken){
        this.props.history.push('/dashboard')
      }
    }

    render() {
      return <WrappedComponent { ...this.props } />;
    }
  }
    
  return HOC;
};

export default Authorization
