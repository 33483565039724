import gql from 'graphql-tag';

export default gql`
  query favouritePromotes($id: ID!) {
    favouritePromotes(id: $id) {
      id
      title
      oldPrice
      newPrice
      discount
      description
      images {
        image
      }
      user {
        id
        email
        image
        fullName
        webUrl
        username
      }
      category {
        id
        name
      }
      topSeller
      startTime
      endTime
      limitedItem
      active
      totalItem
      averageRating
      totlaRating
      clicks
      translations {
        language
        title
        description
      }
    }
  }
`;
