import { LOADING }
  from '../constants';

export default function(state=0, action){
  switch(action.type){
    case LOADING:
      return action.payload
    default:
      return state
  }
}
