import React from 'react'

class UpdateUserInfo extends React.Component {
  constructor(props) {
    super(props);

    let { fullName, username,
      email, phone,
      number, street,
      zip, city,
      country } = this.props.me

    this.state = {
      user: {
        fullName: fullName,
        username: username,
        email: email,
        phone: phone,
        number: number,
        street: street,
        zip: zip,
        city: city,
        country: country,
      },
      success_message: null,
      errors: [],
    };
  }

  onSubmit() {
    var { user } = this.state
    let { startLoading, moveLoading, query  } = this.props;

    this.setState({ errors: [], success_message: null })

    startLoading()
    this.props.mutate({
      variables: { ...user },
      refetchQueries: [{ query }]
    }).then(res => {
      this.setState({ success_message: 'The information was updated successfully' });

      moveLoading()
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({ errors, success_message: null })

      moveLoading()
    })
  }

  render(){
    let {
      fullName,
      username,
      email, phone,
      number, street,
      zip, city,
      country } = this.state.user

    let { translate } = this.props

    return(
      <div className="content-settings">
       { this.state.success_message ?
        <p style={{color: 'green', marginBottom: 10}}>{this.state.success_message}</p>
       :
        <p style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>
       }
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.settings.fullname')}
              </label>
              <input type="text"
                className="form-control"
                value={fullName || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, fullName: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.settings.username')}
              </label>
              <input type="text"
                className="form-control"
                value={username || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, username: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.users.email')}
              </label>
              <input type="text"
                name="name"
                className="form-control"
                value={email || '' }
                onChange={(res) => this.setState({ user: { ...this.state.user, email: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.users.phone')}
              </label>
              <input type="text"
                className="form-control"
                value={phone || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, phone: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.users.street')}
              </label>
              <input type="text"
                className="form-control"
                value={street || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, street: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.users.nr')}
              </label>
              <input type="text"
                className="form-control"
                value={number || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, number: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.users.zip')}
              </label>
              <input type="text"
                className="form-control"
                value={zip || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, zip: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.users.city')}
              </label>
              <input type="text"
                className="form-control"
                value={city || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, city: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>
                {translate('dashboard.users.country')}
              </label>
              <input type="text"
                className="form-control"
                value={country || ''}
                onChange={(res) => this.setState({ user: { ...this.state.user, country: res.target.value } })} />
            </div>
          </div>
          <div className="col-md-6 col-md-6 d-flex align-items-end">
            <div className="form-group button-items">
              <button type="button"
                      className="btn btn-primary btn-full-width"
                      onClick={() => this.onSubmit()} >
                {translate('dashboard.actions.save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UpdateUserInfo
