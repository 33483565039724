import gql from 'graphql-tag';

export default gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      fullName
      bio
      description
      username
      phone
      street
      number
      zip
      city
      country
      image
      webUrl
      facebook
      instagram
      twitter
      topSeller
      role {
        name
      }
      category {
        name
      }
      tags{
        id
        name
        description
      }
      createdAt
      promotes {
        id
        title
        oldPrice
        newPrice
        discount
        createdAt
        clicks
        active
      }
      promoteClicks {
        id
      }
      favoritePromotes {
        id
      }
      provider
      paymentMethod
    }
  }
`;
