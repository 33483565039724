import gql from 'graphql-tag'

export default gql`
  mutation EditUser(
          $id: ID!,
          $email: String,
          $fullName:String,
          $username:String,
          $phone:String,
          $street:String,
          $facebook:String,
          $instagram:String,
          $twitter:String,
          $description: String,
          $zip:String,
          $createdAt: String,
          $city:String,
          $country:String,
          $number:String,
          $topSeller:Boolean,
          $paymentMethod:String){
    editUser(
        id: $id,
        fullName:$fullName,
        email:$email,
        phone: $phone,
        createdAt: $createdAt,
        description: $description,
        street: $street,
        facebook: $facebook,
        twitter: $twitter,
        instagram: $instagram,
        zip: $zip,
        city: $city,
        country: $country,
        number: $number,
        username:$username,
        topSeller:$topSeller,
        paymentMethod:$paymentMethod){
      id
      fullName
      username
      image
      description
      phone
      number
      street
      facebook
      instagram
      twitter
      zip
      city
      country
      email
      topSeller
      paymentMethod
      tags{
        name
        description
      }
    }
  }
`
