import React from 'react';
import { Route, Link }
  from "react-router-dom";
import FavIvonLogo
  from '../../assets/images/logo_favicon/logo.svg';

// Define categories screens
import Promotion
  from './promotions/Promotion';
import NewPromote
  from './promotions/NewPromote';
import EditPromote
  from './promotions/EditPromote';
import ImportPromote
  from './promotions/ImportPromote';
import ShowPromote
  from './promotions/ShowPromote';

import Report
  from './Report';
import Invoice
  from './Invoice';

// Define categories screens
import Category
  from './categories/Category';
import NewCategory
  from './categories/NewCategory';
import EditCategory
  from './categories/EditCategory';

// Define users routes
import Users
  from './users';
import User
  from './users/SingleUser';
import EditUser
  from './users/EditUser';

// supplier routes
import Suppliers
  from './suppliers';
import Supplier
  from './suppliers/SingleSupplier'
import EditSupplier
  from './suppliers/EditSupplier'
import NewSupplier
  from './suppliers/NewSupplier'

import Setting
  from './settings';
import Statistic
  from './Statistic';

import query
  from '../../queries/currentUser';
import { connect }
  from 'react-redux'
import { compose, graphql }
  from 'react-apollo';

import { removeToken }
  from '../../services/token'
import { current_user }
  from '../../actions/user'

import { changeLanguage }
  from '../../actions/language'

import LoadingBar
  from "react-top-loading-bar";

import { finishLoading }
  from '../../actions/loading'

import { getTranslate }
  from 'react-localize-redux';


class Dashboard extends React.Component {
  constructor(props){
    super(props)

    let language = localStorage.getItem('language')
    this.state = {
      language
    }
  }

  logOut(e){
    e.preventDefault();

    removeToken()
    window.location.href = '/auth';
  }

  componentWillReceiveProps(nextProps){
    let { me } = this.props.data
    if(!nextProps.data.loading && nextProps.data.me && me != nextProps.data.me){
      this.props.current_user(nextProps.data.me)
    }
  }

  supperAdminRoutes() {
    if(this.props.me) {
      var { roleId } = this.props.me
      if(roleId == 1) {
        return (
          <div>
            <Route exact path="/dashboard/suppliers" component={Suppliers} />
            <Route exact path="/dashboard/new/suppliers" component={NewSupplier} />
            <Route path="/dashboard/suppliers/:supplier_id" component={Supplier} />
            <Route path="/dashboard/edit/suppliers/:user_id" component={EditSupplier} />

            <Route exact path="/dashboard/users" component={Users} />
            <Route path="/dashboard/users/:user_id" component={User} />
            <Route path="/dashboard/edit/users/:user_id" component={EditUser} />

            <Route exact path="/dashboard/categories" component={Category} />

            <Route exact path="/dashboard/categories/new" component={NewCategory} />
            <Route exact path="/dashboard/category/:category_id" component={EditCategory} />
            <Route exact path="/dashboard/promotions/import" component={ImportPromote} />
          </div>
        )
      }
    }
  }

  supplierRoutes() {
    if(this.props.me) {
      var { roleId } = this.props.me
      if(roleId == 2) {
        return (
          <div>
            <Route path="/dashboard/reports" component={Report} />
            <Route path="/dashboard/invoices" component={Invoice} />
          </div>
        )
      }
    }
  }

  defineRoutes() {
    return (
      <div>
        <section className={`main animated`} data-animation="fadeIn" data-animation-delay="150">
          <Route path="/dashboard/statistics" component={Statistic} />
          <Route exact path="/dashboard/promotions" component={Promotion} />
          <Route exact path="/dashboard/promotions/new" component={NewPromote} />
          <Route exact path="/dashboard/promotions/edit/:promote_id" component={EditPromote} />
          <Route path="/dashboard/settings" component={Setting} />
          {this.supperAdminRoutes()}
          {this.supplierRoutes()}
          <section className="promotion-details" data-animation="fadeIn" data-animation-delay="150">
            <Route exact path="/dashboard/promote/:promote_id" component={ShowPromote} />
          </section>
        </section>
      </div>
    )
  }

  displayLinks() {
    if(this.props.me) {
      var { roleId } = this.props.me
      if(roleId == 1) {
        return (
          this.displayLinksForSuperAdmin()
        )
      } else if(roleId == 2){
        return (
          this.displayLinksForSupplier()
        )
      }
    }
  }

  displayLinksForSuperAdmin() {
    let { translate } = this.props;

    return (
      <ul className="menu">
          <li>
            <Link
              className={this.props.location.pathname.indexOf("dashboard/statistics") > -1 ? 'active' : ''}
              to={`/dashboard/statistics`}>
              <i className="icon-home"></i>{ translate('dashboard.tab.dashboard') }
            </Link>
          </li>
          <li>
            <Link
              className={this.props.location.pathname.indexOf("/suppliers") > -1 ? 'active' : ''}
              to={`/dashboard/suppliers`}>
              <i className="icon-suppliers"></i> { translate('dashboard.tab.suppliers') }
            </Link>
          </li>
          <li>
            <Link
              className={
                this.props.location.pathname.indexOf("dashboard/promotions") > -1 ? 'active' : '' ||
                this.props.location.pathname.indexOf("dashboard/promote") > -1 ? 'active' : ''
              }
              to={`/dashboard/promotions`}
              ><i className="icon-promotions"></i>{ translate('dashboard.tab.promotions') }</Link>
          </li>
          <li>
            <Link
              className={
                this.props.location.pathname.indexOf("dashboard/categories") > -1 ? 'active' : '' ||
                this.props.location.pathname.indexOf("dashboard/category") > -1 ? 'active' : ''
              }
              to={`/dashboard/categories`}><i className="icon-categories"></i>{ translate('dashboard.tab.category') }
            </Link>
          </li>
          <li>
            <Link
              className={
                this.props.location.pathname.indexOf("dashboard/users") > -1 ? 'active' : '' ||
                this.props.location.pathname.indexOf("dashboard/edit/users") > -1 ? 'active' : ''
              }
              to={`/dashboard/users`}><i className="icon-users"></i>{ translate('dashboard.tab.users') }
            </Link>
          </li>
          <li>
            <Link
              className={this.props.location.pathname.indexOf("dashboard/settings") > -1 ? 'active' : ''}
              to={`/dashboard/settings/update`}><i className="icon-settings"></i>{ translate('dashboard.tab.settings') }
            </Link>
          </li>
      </ul>
    )
  }

  displayLinksForSupplier() {
    let { translate } = this.props;

    return (
      <ul className="menu">
          <li>
            <Link
              className={this.props.location.pathname.indexOf("dashboard/statistics") > -1 ? 'active' : ''}
              to={`/dashboard/statistics`}>
              <i className="icon-home"></i>{ translate('dashboard.tab.dashboard') }
            </Link>
          </li>
          <li>
            <Link
              className={
                this.props.location.pathname.indexOf("dashboard/promotions") > -1 ? 'active' : '' ||
                this.props.location.pathname.indexOf("dashboard/promote") > -1 ? 'active' : ''
              }
              to={`/dashboard/promotions`}
              ><i className="icon-promotions"></i>{ translate('dashboard.tab.promotions') }</Link>
          </li>
          <li>
            <Link
              className={this.props.location.pathname.indexOf("dashboard/settings") > -1 ? 'active' : ''}
              to={`/dashboard/settings/update`}><i className="icon-settings"></i>
                { translate('dashboard.tab.settings') }
            </Link>
          </li>
      </ul>
    )
  }

  changeLanguage(event){
    let lan = event.target.value

    localStorage.setItem('language', lan)

    this.props.changeLanguage(lan)

    this.setState({
      language: lan
    })
  }

  render() {
    let { translate, locale } = this.props

    let { language } = this.state
    return(
      <div>
        <LoadingBar
          progress={this.props.loading}
          height={3}
          color="red"
          onLoaderFinished={() => this.props.finishLoading() }
        />

        <header className="main-header">
            <div className="header">
                <div className="logo">
                  <img src={FavIvonLogo} />
                </div>
                <div className="right-content">
                    <div className="notifications">
                        <a href="#">
                               <i className="icon-notifications"></i>
                          { /* <span className="number">10</span> */ }
                        </a>
                        <div className="notification-dropdown">
                        </div>
                    </div>

                    <div className="lang">
                      <form>
                        <div className="select-custom">
                          <select defaultValue={language || 'en'} onChange={this.changeLanguage.bind(this)} className="form-control">
                            <option defaultValue={language == 'en'} value='en'>
                              { translate('dashboard.settings.languages.english') }
                            </option>
                            <option defaultValue={language == 'de'} value='de'>
                              { translate('dashboard.settings.languages.deutsch') }
                            </option>
                            <option defaultValue={language == 'it'} value='it'>
                              { translate('dashboard.settings.languages.italian') }
                            </option>
                            <option defaultValue={language == 'fr'} value='fr'>
                              { translate('dashboard.settings.languages.french') }
                            </option>
                          </select>
                        </div>
                      </form>
                    </div>

                    <div className="screens">
                      <a href="#" onClick={this.logOut.bind(this)} >
                        {this.props.me ? this.props.me.fullName : ''} <i className="icon-logout"></i>
                      </a>
                    </div>
                    <div className="toggle-responsive">
                        <a href="#" className="click-menu">
                            <span className="lines line-top"></span>
                            <span className="lines line-mid"></span>
                            <span className="lines line-bottom"></span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="sub-header screen-desktop">
                {this.displayLinks()}
                <div className="screens">
                    <a href="#">{this.props.me ? this.props.me.fullName : ''} <i className="icon-logout"></i></a>
                </div>
            </div>
        </header>
        {this.defineRoutes()}
      </div>
    )
  }
}

function mapStateToProps({ current_user, loading, locale }){
  return { me: current_user,
           translate: getTranslate(locale),
           loading }
}

export default compose(
  graphql(query),
  connect(mapStateToProps, { changeLanguage,
    current_user,
    finishLoading })
)(Dashboard);
