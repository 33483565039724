import gql from 'graphql-tag'

export default gql`
  mutation UpdatePromote($language: String,
                         $promoteId: Int!,
                         $title: String!,
                         $userId: Int!,
                         $oldPrice: Float!,
                         $description: String!,
                         $newPrice: Float!,
                         $discount: Float!,
                         $categoryId: Int,
                         $categories: [Int!]!,
                         $startTime: String!,
                         $endTime: String!,
                         $topSeller: Boolean!,
                         $active: Boolean!,
                         $limitedItem: Int,
                         $totalItem: Int,
                         $onSlider: Boolean!){
    updatePromote(language:$language
                  promoteId:$promoteId,
                  title:$title,
                  userId:$userId,
                  oldPrice:$oldPrice,
                  description:$description,
                  newPrice:$newPrice,
                  discount:$discount,
                  categoryId:$categoryId,
                  categories:$categories,
                  startTime:$startTime,
                  endTime:$endTime,
                  topSeller:$topSeller,
                  active:$active,
                  limitedItem:$limitedItem,
                  totalItem:$totalItem,
                  onSlider:$onSlider) {
          id
          title
          oldPrice
          newPrice
          discount
          description
          images {
            image
          }
          user {
            id
            email
            image
            webUrl
            username
          }
          categories {
            id
            name
          }
          topSeller
          startTime
          endTime
          limitedItem
          active
          onSlider
          totalItem
          averageRating
          totlaRating
          translations {
            language
            title
            description
          }
    }
  }
`
