import React from 'react';
import NewPromoteInputs
  from '../../../components/promotions/NewPromoteInputs';
import { startLoading, moveLoading }
  from '../../../actions/loading';
import { connect }
   from 'react-redux';
import { graphql, compose }
 from 'react-apollo';


class NewPromote extends React.Component {
  render() {
    return(
      <div className="container page">
        <div className="row">
          <div className="col-md-12">
            <div className="page-head">
              <div className="page-title">
                <h5>Add Promotion</h5>
              </div>
              <div className="right-info">
                <div className="close-button">
                  <a onClick={() => this.props.history.goBack()}>
                    <span>Close</span>
                    <i className="icon-close"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <NewPromoteInputs {...this.props} />
        </div>
      </div>
    )
  }
}

function mapStateToProps({ current_user, loading }){
  return { me: current_user, loading }
}

export default compose(
  connect(mapStateToProps, { startLoading, moveLoading })
)(NewPromote);
