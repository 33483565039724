import React from 'react';
import { Route, Link }
  from "react-router-dom";

import ShowUsersList
  from './ShowUsersList'

class ClientsList extends React.Component {
  render () {
    var { mutate, 
          offset,
          translate,
          clients, 
          changeOffset } = this.props

    return(
      <ShowUsersList
        mutate={mutate}
        translate={translate}
        changeOffset={changeOffset}
        offset={offset}
        totalItems={clients.count}
        users={clients.rows} />
    )
  }
}

export default ClientsList;
