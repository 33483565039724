import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';

import searchUsersQuery 
  from '../../queries/user/searchUsers'

import ShowUsersList
  from './ShowUsersList'

import ShowSupplierList 
  from '../suppliers/ShowSupplierList'

class SearchedItems extends React.Component {
  render () {
    if(this.props.data.loading) { return <div /> }

    var { searchUsers } = this.props.data

    if(this.props.type === 'supplier'){
      return(
        <ShowSupplierList 
          mutate={this.props.mutate}
          totalItems={0}
          translate={this.props.translate}
          offset={null}
          users={searchUsers} />
      )
    }else{
      return(
        <ShowUsersList 
          mutate={this.props.mutate}
          query={searchUsersQuery}
          translate={this.props.translate}
          search={this.props.search}
          users={searchUsers} />
        )
    } 
  }
}

const SearchPromotesExecutor = graphql(searchUsersQuery, {
  options: (props) => { return { variables: { search: props.search, type: props.type } } }
})

export default (SearchPromotesExecutor)(SearchedItems);
