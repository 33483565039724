import React from 'react';
import moment from 'moment';

import { Route, Link }
  from "react-router-dom";

import UploadImagefields
  from './UploadImageFields';

import Loader
  from 'react-loader-spinner'

import { graphql, compose }
  from 'react-apollo';

import fetchCategories
  from '../../queries/category/fetchCategoryOptions';

import searchPromotes
  from '../../queries/promote/searchPromotes';

import createPromote
  from '../../mutations/dashboard/promotes/createPromote';

import addPromoteImages
  from '../../mutations/dashboard/promotes/addPromoteImages';

import { connect }
   from 'react-redux';

import uploadImage
  from '../../services/uploadImage';

import { startLoading, moveABitLoading, moveLoading }
  from '../../actions/loading';

import Select
  from 'react-select';

import fetchPromotions
  from '../../queries/promote/fetchPromotions';

import fetchPromote
  from '../../queries/promote/fetchPromote';

  import DayPicker from 'react-day-picker';
  import 'react-day-picker/lib/style.css';

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    paddingBottom: 40,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: "100%",
    marginBottom: 20
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

class NewPromoteInputs extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      mainImage: '',
      image1: '',
      image2: '',
      image3: '',
      image4: '',
      title: '',
      normalPrice: '',
      description: '',
      offerPrice: '',
      discount: '',
      category: '',
      startDate: '',
      endDate: '',
      topSeller: false,
      totalItem: null,
      limitedItem: null,
      active: true,
      onSlider: false,
      errors: 0,
      categories: [],
    }
  }

  handleImageChange(event, image) {
    this.setState({
      [image]: event.target.files[0]
    })
  }

  handleChangeItem(state, event, error) {
    this.setState({
      [state]: event.target.value,
      [error]: ''
    })

    if(state == 'offerPrice') {
      this.calculateDiscount(this.state.normalPrice, event.target.value)
    }

    if(state == 'normalPrice') {
      this.calculateDiscount(event.target.value, this.state.offerPrice)
    }
  }

  calculateDiscount(normalPrice, offerPrice) {
    if(normalPrice != '' && offerPrice != '') {
      let res = (offerPrice / normalPrice) * 100
      let total = 100 - res
      if(total < 0) {
        this.setState({discount: 'NaN'})
      } else {
        this.setState({discount: 100 - res})
      }
    } else {
      this.setState({discount: ''})
    }
  }

  handleChangeBoolean(type, state) {
    this.setState({
      [type]: !state
    })
  }

  onChangeCategory(event) {
    this.setState({category: event.target.value});
  }

  submitPromoteForm() {
    let { startLoading,
          moveLoading } = this.props;
    let catSelected = []
    var { title,
          normalPrice,
          description,
          offerPrice,
          normalprice,
          category,
          discount,
          startDate,
          endDate,
          errors,
          topSeller,
          active,
          onSlider,
          totalItem,
          limitedItem,
          categories } = this.state

    if(parseInt(offerPrice) > parseInt(normalPrice)) {
      return alert("Offer price is bigger than normal price")
    }


    if(this.handleRequireFields() && parseInt(offerPrice) <= parseInt(normalPrice)) {
      this.props.startLoading()
      this.setState({loading: true})

      categories.map((category, idx) => {
        catSelected.push(parseInt(category.value));
      })

      this.props.mutate({
        mutation: createPromote,
        variables: { title, userId: parseInt(this.props.me.id), oldPrice: parseFloat(normalPrice), description,
                     newPrice: parseFloat(offerPrice), discount: parseFloat(discount.toFixed(0)), categoryId: parseInt(category),
                     startTime: Date.parse(startDate).toString(), endTime: Date.parse(endDate).toString(),
                     topSeller, active, onSlider, categories: catSelected, totalItem: parseInt(totalItem), limitedItem: parseInt(limitedItem) },
      }).then(res => {
        this.setState({promoteId: res.data.createPromote.id})
        this.props.moveABitLoading()
        this.requestImage()
      }).catch(res => {
        const errors = res.graphQLErrors.map((err) => err.message)
        this.props.moveLoading()
        this.setState({loading: false})
      })
    } else {
      this.props.moveLoading()
    }
  }

  displaySubmitButton() {
    if(this.state.loading) {
      return (
        <div style={{marginLeft: "44%"}}>
          <Loader
            type="Bars"
            color="#ff0844"
            height="50"
          />
        </div>
      )
    } else {
      return(
       <button
        type="button"
        className="btn btn-primary btn-full-width"
        onClick={() => this.submitPromoteForm()} >Add</button>
      )
    }

  }

  displayTopSellerField() {
    if(this.props.me) {
      if(this.props.me.roleId == 1) {
        return (
          <div className="col-md-4 d-flex align-items-center">
            <div className="checkbox">
              <input
                type="checkbox"
                id="datelimited"
                value="Checbox"
                checked={this.state.topSeller ? true : false }
                onChange={(value) => this.handleChangeBoolean('topSeller', this.state.topSeller, 'topSellerError')} />
              <label for="datelimited">TopSeller</label>
            </div>
          </div>
        )
      }
    }
  }

  handleChange = (selectedOption) => {
    this.setState({ categories: selectedOption });
    console.log(`Option selected:`, selectedOption);
  }

  addCategoriesOptions() {
    const { categories } = this.state;
    let catOptions = []
    let { categoryOptions } = this.props.data

    categoryOptions.map((category, idx) => {
      catOptions.push({ value: category.id, label: category.name });
    })

    return (
      <Select
        value={categories}
        styles={customStyles}
        onChange={this.handleChange}
        options={catOptions}
        isMulti={true}
        isSearchable={true}
        placeholder={""}
      />
    );
  }

  render () {
    let { loading, categoryOptions } = this.props.data

    if(loading) {
      return <div></div>
    }

    return(
      <div className="col-md-10">
        <UploadImagefields
          handleImageChange={this.handleImageChange.bind(this)}
          mainImage={this.state.mainImage}
          image1={this.state.image1}
          image2={this.state.image2}
          image3={this.state.image3}
          image4={this.state.image4} />
        <div className="row">
          <div className="col-md-6">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.titleError}</p>
            <div className="form-group">
              <label>Title</label>
              <input
                type="text"
                name="title"
                className="form-control"
                value={this.state.title}
                onChange={(value) => this.handleChangeItem('title', value, 'titleError')} />
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group">
              <label>Select categories</label>
              <p style={{fontSize: 12, color: 'red'}}>{this.state.categoryError}</p>
              {this.addCategoriesOptions()}
            </div>
          </div>
          <div className="col-md-12">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.descriptionError}</p>
            <div className="form-group">
              <label>Description</label>
              <textarea
                rows="5"
                className="form-control"
                value={this.state.description}
                onChange={(value) => this.handleChangeItem('description', value, 'descriptionError')} >
              </textarea>
            </div>
          </div>

          <div className="col-md-4">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.normalPriceError}</p>
            <div className="form-group">
             <label>Normal price</label>
              <input
                type="text"
                name="normalprice"
                className="form-control"
                value={this.state.normalPrice}
                onChange={(value) => this.handleChangeItem('normalPrice', value, 'normalPriceError')} />
              <span className="price-percent">CHF</span>
            </div>
          </div>
          <div className="col-md-4">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.offerPriceError}</p>
            <div className="form-group">
              <label>Offer price</label>
              <input
                type="text"
                name="offerprice"
                className="form-control"
                value={this.state.offerPrice}
                onChange={(value) => this.handleChangeItem('offerPrice', value, 'offerPriceError')} />
              <span className="price-percent">CHF</span>
            </div>
          </div>
          <div className="col-md-4">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.discountError}</p>
            <div className="form-group">
             <label>Discount</label>
              <input
                type="text"
                name="percent"
                className="form-control"
                disabled={true}
                value={ this.state.discount != 0 && this.state.discount != '' && this.state.discount != 'NaN' ?
                        this.state.discount.toFixed(0) : 0}
                onChange={(value) => this.handleChangeItem('discount', value, 'discountError')} />
              <span className="price-percent">%</span>
            </div>
          </div>
          <div className="col-md-6">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.startDateError}</p>
            <div className="form-group form-date">
              <label>Start date</label>
              <input
                type="date"
                name="date"
                className="form-control"
                value={this.state.startDate}
                onChange={(value) => this.handleChangeItem('startDate', value, 'startDateError')}
                />
            </div>
          </div>
          <div className="col-md-6">
            <p style={{fontSize: 12, color: 'red'}}>{this.state.endDateError}</p>
            <div className="form-group form-date">
              <label>End date</label>
              <input
                type="date"
                name="date"
                className="form-control"
                value={this.state.endDate}
                onChange={(value) => this.handleChangeItem('endDate', value, 'endDateError')} />
            </div>
          </div>
          {this.displayTopSellerField()}
          <div className="col-md-4">
            <div className="form-group">
              <label>Total Item</label>
              <input
                type="number"
                name="percent"
                className="form-control"
                value={this.state.totalItem}
                onChange={(value) => this.handleChangeItem('totalItem', value, 'totalItemError')} />
              <span className="price-percent"></span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Limited item</label>
              <input
                type="number"
                name="percent"
                className="form-control"
                value={this.state.limitedItem}
                onChange={(value) => this.handleChangeItem('limitedItem', value, 'limitedItemError')} />
              <span className="price-percent"></span>
            </div>
          </div>
        </div>

        <div className="row">
            <div className="col-md-3">
                <div className="checkbox">
                    <input
                      type="checkbox"
                      id="piecelimited"
                      value="Checbox"
                      checked={this.state.active ? true : false }
                      onChange={(value) => this.handleChangeBoolean('active', this.state.active, 'activeError')}  />
                    <label for="piecelimited">Active</label>
                </div>
            </div>
            <div className="col-md-3">
                <div className="checkbox">
                    <input
                      type="checkbox"
                      id="onSliderId"
                      value="Checbox"
                      checked={this.state.onSlider ? true : false }
                      onChange={(value) => this.handleChangeBoolean('onSlider', this.state.onSlider, 'onSliderError')}  />
                    <label for="onSliderId">On Slider</label>
                </div>
            </div>
            <div className="col-md-6">
                <div className="form-group">
                  {this.displaySubmitButton()}
                </div>
            </div>
        </div>
      </div>
    )
  }

  async requestImage() {
    let mainImage = await uploadImage(this.state.mainImage)
    let image1 = this.state.image1 != '' ? await uploadImage(this.state.image1) : ''
    let image2 = this.state.image2 != '' ? await uploadImage(this.state.image2) : ''
    let image3 = this.state.image3 != '' ? await uploadImage(this.state.image3) : ''
    let image4 = this.state.image4 != '' ? await uploadImage(this.state.image4) : ''

    this.addImages(mainImage, image1, image2, image3, image4)
  }

  addImages(mainImage, image1, image2, image3, image4) {
    this.setState({
      mainImageUpload: mainImage,
      image1Upload: image1,
      image2Upload: image2,
      image3Upload: image3,
      image4Upload: image4
    })
    this.saveToDatabase()
  }

  saveToDatabase() {
    let images = [this.state.mainImageUpload, this.state.image1Upload, this.state.image2Upload, this.state.image3Upload, this.state.image4Upload]
    let imageUpload = images.filter((image) => image != '')

    this.props.mutate({
      mutation: addPromoteImages,
      variables: { gallery: imageUpload, promoteId: parseInt(this.state.promoteId)},
      refetchQueries: [
        {
          query: fetchPromotions
        },
        {
          query: fetchPromote, variables: { id: parseInt(this.state.promoteId) }
        }
      ]
    }).then(res => {
      this.setState({loading: false})
      this.props.moveLoading()
      this.props.history.push(`/dashboard/promote/${parseInt(this.state.promoteId)}`)
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      alert(errors[0])
    })
  }

  handleRequireFields() {
    var { mainImage, title, normalPrice, description, offerPrice, normalprice, category, categories, discount, startDate, endDate, errors} = this.state
    if(!mainImage) {
      alert("Main image can not be blank")
      return 0
    }

    Object.keys(title.split(' ').join('')).length == 0 ? this.setState({titleError: 'Title should not be blank !..', errors: errors + 1}) : this.setState({titleError: '', errors: errors == 0 ? 0 : errors - 1})
    normalPrice == '' ? this.setState({normalPriceError: 'Normal price should not be blank !..', errors: errors + 1}) : this.setState({normalPriceError: '', errors: errors == 0 ? 0 : errors - 1})
    Object.keys(description.split(' ').join('')).length == 0 ? this.setState({descriptionError: 'Description should not be blank !..', errors: errors + 1}) : this.setState({descriptionError: '', errors: errors == 0 ? 0 : errors - 1})
    offerPrice == '' ? this.setState({offerPriceError: 'Offer price should not be blank !..', errors: errors + 1}) : this.setState({offerPriceError: '', errors: errors == 0 ? 0 : errors - 1})
    normalPrice == '' ? this.setState({normalPriceError: 'Normal price should not be blank !..', errors: errors + 1}) : this.setState({normalPriceError: '', errors: errors == 0 ? 0 : errors - 1})
    discount == '' ? this.setState({discountError: 'Discount should not be blank !..', errors: errors + 1}) : this.setState({discountError: '', errors: errors == 0 ? 0 : errors - 1})
    categories.length == 0 ? this.setState({categoryError: 'Category should not be blank !..', errors: errors + 1}) : this.setState({categoryError: '', errors: errors == 0 ? 0 : errors - 1})
    startDate == "Invalid date" || startDate == "" ? this.setState({startDateError: 'Start date should not be blank !..', errors: errors + 1}) : this.setState({startDateError: '', errors: errors == 0 ? 0 : errors - 1})
    endDate == "Invalid date" || endDate == "" ? this.setState({endDateError: 'End date should not be blank !..', errors: errors + 1}) : this.setState({endDateError: '', errors: errors == 0 ? 0 : errors - 1})

    var { titleError, normalPriceError, descriptionError, offerPriceError, normalPriceError, discountError, categoryError, startDateError, endDateError} = this.state


        return Object.keys(title.split(' ').join('')).length != 0 && normalPrice != ''
            && Object.keys(description.split(' ').join('')).length != 0 && offerPrice != ''
            && discount != ''
            && categories.length != 0 && (startDate != "Invalid date" || startDate != "")
            && (endDate != "Invalid date" || endDate != "")

  }
}

function mapStateToProps({ current_user, loading }){
  return { me: current_user, loading }
}

export default compose(
  graphql(fetchCategories),
  graphql(createPromote),
  graphql(addPromoteImages),
  connect(mapStateToProps, { startLoading, moveABitLoading, moveLoading })
)(NewPromoteInputs)
