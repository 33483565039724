import gql from 'graphql-tag';

export default gql`
  query PromotesQuery($offset: Int) {
    promotes(limit: 20, offset: $offset) {
      id
      title
      oldPrice
      newPrice
      discount
      onSlider
      visits
      images {
        image
      }
      user {
        id
        email
        image
        webUrl
        username
      }
      category {
        id
        name
      }
      categories {
        id
        name
      }
      userClicks {
        id
      }
    }
  }
`;
