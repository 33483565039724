import React from 'react'

import Login
  from './Login'

import ResetPassword
  from './ResetPassword'

import ForgotPassword
  from './ForgotPassword'

import { Route, Link }
  from "react-router-dom";

import Logo
  from '../../assets/images/images/login.png'

const Auth = (props) => {
  return (
    <section className="auth animated" data-animation="fadeIn" data-animation-delay="150">
      <div className="mockup">
        <img alt="" src={Logo} />
      </div>
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-md-5">
           <Route exact path="/auth" component={Login} />
           <Route exact path="/auth/forgetpassword" component={ForgotPassword} />
           <Route exact path="/auth/resetPassword/:token" component={ResetPassword} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Auth
