import gql from 'graphql-tag';

export default gql`
  query searchPromotesWithTypeQuery($language: String!, $search: String!, $typeId: ID!, $sortBy: String!) {
    searchPromotes: searchPromotesWithTypeEndSort(language: $language, search: $search, typeId: $typeId, sortBy: $sortBy ) {
      id
      title
      oldPrice
      newPrice
      discount
      images {
        image
      }
      user {
        id
        email
        image
        webUrl
        username
      }
      category {
        id
        name
      }
      categories {
        id
        name
      }
      topSeller
      averageRating
      totlaRating
    }
  }
`;
