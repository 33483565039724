import gql from 'graphql-tag';

export default gql`
  {
    me{
      id
      fullName
      username
      roleId
      image
      description
      bio
      phone
      number
      street
      zip
      city
      country
      notificationStatus
      webUrl
      email
      tags{
        id
        name
        description
      }
    }
  }
`;
