import moment
  from 'moment';

export const addImportItem = (item, index, response) => {
  if(validRows(item) == true) {
    response(
              {
                index: index,
                items: {
                  title: item[0], oldPrice: item[1], description: item[2], newPrice: item[3], discount: item[4],
                  category: item[5], startDate: moment(item[6]).format('YYYY-MM-DD'), endDate: moment(item[7]).format('YYYY-MM-DD'), topSeller: item[8], totalItem: item[9] ? item[9] : 0,
                  limitedItem: item[10] ? item[10] : 0, active: item[11], mainImage: item[12], image1: item[13] ? item[13] : null,
                  image2: item[14] ? item[14] : null, image3: item[15] ? item[15] : null, image4: item[16] ? item[16] : null
                },
                error: null
              }
            )
  } else {
    response(
            {
              index: index,
              items: null,
              error: validRows(item)
            }
          )
  }
}

export const checkExcelRow = () => {
  return "checkExcelRow"
}

export const validRows = (item) => {
  if(item[0] == undefined) {
    return "Empty title"
  } else if (item[1] == undefined) {
    return "Empty normal Price"
  } else if (item[2] == undefined) {
    return "Empty description"
  } else if (item[3] == undefined) {
    return "Empty old price"
  } else if (item[4] == undefined) {
    return "Empty discount"
  } else if (item[5] == undefined ) {
    return "Empty category"
  } else if (item[6] == undefined) {
    return "Empty start date"
  } else if (item[7] == undefined) {
    return "Empty end date"
  } else if (item[8] == undefined) {
    return "Empty topSeller"
  } else if (item[11] == undefined) {
    return "Empty active"
  } else if (item[12] == undefined) {
    return "Empty main image"
  } else {
    return true
  }
}
