import gql from 'graphql-tag';

export default gql`
  query SearchUsers($search: String!, $type: String) {
    searchUsers(search: $search, type: $type) {
      id
      fullName
      email
      createdAt
    }
  }
`;

