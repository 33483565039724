import gql from 'graphql-tag';

export default gql`
  query topSellerSupplierPromotes($userId: Int!) {
    topSellerSupplierPromotes(userId: $userId) {
      id
      title
      oldPrice
      newPrice
      discount
      images {
        image
      }
      clicks
    }
  }
`;
