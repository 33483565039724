import React from 'react';

import EditPromoteInputs
  from '../../../components/promotions/EditPromoteInputs';
import ExportTemplate
  from '../../../components/promotions/ExportTemplate';
import ImportedPromoteItem
  from '../../../components/promotions/ImportedPromoteItem';
import { startLoading, moveLoading }
  from '../../../actions/loading';

import fetchCategories
  from '../../../queries/category/fetchCategoryOptions';
import { connect }
   from 'react-redux';

import { graphql, compose }
 from 'react-apollo';
import { getTranslate }
  from 'react-localize-redux';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import { addImportItem, checkExcelRow }
  from '../../../services/importPromoteService';
import Loader from 'react-loader-spinner';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ImportPromote extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      xlsFile: null,
      error: null,
      importItems: [],
      step: 1,
      loading: false
    }
  }

  fileHandler = (event) => {
    let fileObj = event.target.files;

    if(fileObj.length > 0) {
      this.setState({xlsFile: fileObj})
    } else {
      this.setState({xlsFile: null})
    }
  }

  displayButton() {
    if(this.state.loading) {
      return (
        <Loader
          type="Bars"
          color="#ff0844"
          height="50"
        />
      )
    } else {
      return(
        <a href="#" onClick={() => this.submitButton()} class="btn btn-primary">Upload</a>
      )
    }
  }

  submitButton = () => {
    let { xlsFile } = this.state
    if(xlsFile != null) {
      this.setState({loading: true})

      let importItems = []

      let fileObj = xlsFile[0];
      if(this.checkFileFormat(fileObj)) {
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);
          }
          else{
            resp.rows.map((value, index) => {
              if(index != 0) {
                if(value.length != 0) {
                  addImportItem(value, index, this.createImportItems.bind(this))
                }
              }
            })
            this.setState({loading: false, step: 2})
          }
        });
      } else {
        this.setState({error: "Format is not valid, you can add only excel file"})
        this.setState({loading: false})
      }
    } else {
      this.setState({error: "Please add any file!.."})
    }
  }

  createImportItems(item) {
    var joined = this.state.importItems.concat(item);
    this.setState({ importItems: joined })
  }

  checkFileFormat(fileObj) {
    var formatFile = fileObj.name.split(/\.(?=[^\.]+$)/);
    let formatNameFile = formatFile[formatFile.length - 1]

    if(formatNameFile == "xlsx" || formatNameFile == "xls") {
      return true
    } else {
      return false
    }
  }

  displayFileSection() {
    let { xlsFile } = this.state
    if(xlsFile == null) {
      return (
        <div class="image-upload-wrap">
            <input type="file" className="file-upload-input" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
            <div class="drag-text">
                <span>Drag and drop file, or choose from your file</span>
                <a href="#" class="btn btn-upload">Choose file</a>
            </div>
        </div>
      )
    } else {
      return (
        <div class="image-upload-wrap">
          <input type="file" className="file-upload-input" onChange={this.fileHandler.bind(this)} style={{"padding":"10px"}} />
          <div class="drag-text">
            <span>Uploaded, {xlsFile[0].name}</span>
          </div>
        </div>
      )
    }
  }

  displayError() {
    setTimeout( () => {
      this.setState({error: null})
      },
    4000);
    if(this.state.error != null) {
      return (
        <p style={{color: 'red'}}>{this.state.error}</p>
      )
    }
  }

  download() {
  // fake server request, getting the file url as response
    setTimeout(() => {
      const response = {
        file: '../../../templates/import_template.xlsx',
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
    }, 100);
  }

  goBackStep() {
    this.setState({step: 1, loading: false, importItems: []})
  }

  renderResultSection() {
    if(this.state.step == 1) {
      return (
        <div class="container page">
          <div class="row">
            <div class="col-md-12">
              <div class="page-head">
                <div class="left-info">
                  <div class="back">
                    <a onClick={() => this.props.history.goBack()}>
                      <i class="icon-arrow-left"></i>
                        <span>Back</span>
                    </a>
                  </div>
                </div>
                <div class="page-title">
                  <h5>Import Promotions</h5>
                </div>
                <div class="right-info">
                  <div class="back">
                    <ExportTemplate />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-md-7">
              <div class="main-file-upload file-button">
                {this.displayError()}
                <div class="file-upload">
                  {this.displayFileSection()}
                </div>
                {this.displayButton()}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div class="container page">
          <div class="row">
            <div class="col-md-12">
              <ImportedPromoteItem
                goBackStep={() => this.goBackStep()}
                historyBack={() => this.props.history.goBack()}
                importItems={this.state.importItems}
              />
            </div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      this.renderResultSection()
    )
  }
}

function mapStateToProps({ locale, current_user, loading }){
  return { me: current_user, loading,
          translate: getTranslate(locale) }
}

export default compose(
  connect(mapStateToProps, { startLoading, moveLoading })
)(ImportPromote);
