import gql from 'graphql-tag'

export default gql`
    mutation UpdateYourData($email: String,
                            $bio: String,
                            $fullName:String,
                            $username:String,
                            $phone:String,
                            $street:String,
                            $zip:String,
                            $city:String,
                            $password: String,
                            $currentPassword: String,
                            $confirmPassword: String,
                            $country:String,
                            $number:String,
                            $image: String,
                            ){
      updateYourData(fullName:$fullName,
                     bio:$bio,
                     email:$email,
                     password: $password,
                     phone: $phone,
                     street: $street,
                     zip: $zip,
                     currentPassword: $currentPassword,
                     confirmPassword: $confirmPassword,
                     city: $city,
                     country: $country,
                     number: $number,
                     image: $image,
                     username:$username){
         id
         fullName
         username
         image
         description
         phone
         number
         street
         zip
         city
         country
         webUrl
         email
         tags{
           name
           description
         }
      }
    }
`

