import gql from 'graphql-tag';

export default gql`
  query Category($id: ID!) {
    category(id: $id) {
      id
      name
      user {
        email
      }
      translations {
        name
        language
      }
    }
  }
`;
