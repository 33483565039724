import React from 'react';

import { graphql, compose }
  from 'react-apollo';

import fetchClients
  from '../../../queries/user/fetchClients';

import deleteUser
  from '../../../mutations/users/deleteUser'

import ClientsList
  from '../../../components/users/ClientsList';
import SearchedItems
  from '../../../components/users/SearchedItems';

import { getTranslate }
  from 'react-localize-redux';
import LoadingGif
 from '../../../assets/images/images/lgLoader.gif'

import { connect }
  from 'react-redux'
import LIMIT
  from '../../../constants/pagination_limit';
import Select from 'react-select';
var _ = require('lodash');

const OFFSET = 0

class Users extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      search: '',
      offset: OFFSET,
      sortSelect: null,
      sorts: [
              {value: 'fullName', label: this.props.translate('dashboard.users.name')},
              {value: 'createdAt', label: this.props.translate('dashboard.filters.register_date')},
              {value: 'email', label: this.props.translate('dashboard.users.email') },
            ]
    }
  }

  sortOnChange(event){
    this.setState({
      sort: event.value, sortSelect: event
    })
    this.props.data.refetch({ order: event.value })
  }

  changeOffset(e, offset){
    e.preventDefault();

    let { clients } = this.props.data;
    let times = Math.round(clients.count/LIMIT)

    if(clients.rows && times < offset ){ return null; }

    this.setState({
      offset
    })

    this.props.data.refetch({ order: 'createdAt', offset: LIMIT * offset, limit: LIMIT })
  }

  setSearchTerm = _.debounce(search => {
     this.setState({ search })
  }, 1000)

  render() {
    let { loading, clients } = this.props.data

    let { translate } = this.props

    if(loading) {
      return <div></div>
    }

    return(
      <div>
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-md-5">
                    <div className="form-group">
                        <div className="search">
                          <input type="text"
                                 className="form-control"
                                 placeholder={ translate('dashboard.actions.search') }
                                 onChange={e => {this.setSearchTerm(e.target.value)}}
                               />
                            <i className="icon-search"></i>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row justify-content-end">
                        <div className="col-md-4">
                            <div className="select-custom select-full-width">
                              <Select
                                value={this.state.sortSelect}
                                onChange={this.sortOnChange.bind(this)}
                                options={this.state.sorts}
                                placeholder={translate('dashboard.filters.sort_by')}
                              />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        { this.state.search != '' ?
         <SearchedItems mutate={this.props.mutate}
                        translate={translate}
                        search={this.state.search} />
        :
         <ClientsList mutate={this.props.mutate}
                      changeOffset={this.changeOffset.bind(this)}
                      translate={translate}
                      offset={this.state.offset}
                      clients={clients}/>
        }
      </div>
    )
  }
}

function mapStateToProps({ locale }){
  return { translate: getTranslate(locale), }
}


export default compose(
  connect(mapStateToProps),
  graphql(deleteUser),
  graphql(fetchClients, {
    options: (props) => {  return { variables: { order: 'createdAt', offset: OFFSET, limit: LIMIT } } }
  })
)(Users);
