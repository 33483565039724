import React
  from 'react';

import moment
  from 'moment';

import fetchUser
  from '../../../queries/user/singleUser'

import { graphql, compose }
  from 'react-apollo';

import { Route, Link }
  from "react-router-dom";

import fetchClients
  from '../../../queries/user/fetchClients';
import PromoteClicksList
  from '../../../components/users/PromoteClicksList';
import FavouritePromotes
  from '../../../components/users/FavouritePromotes';

import deleteUser
  from '../../../mutations/users/deleteUser';
import { getTranslate }
  from 'react-localize-redux';
import { connect }
  from 'react-redux';

class User extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      typeId: 1,
      sortBy: "createdAt",
      status: true,
      promotesClickLength: 0
    }
  }

  removeUser(userId){
    this.props.mutate({
      variables: { id: userId },
      refetchQueries: [ { query: fetchClients } ]
    }).then(res => {
      window.location.href = '/dashboard/users';
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({errors: errors[0]})
      console.log(errors)
    })
  }

  onChangeFilter(event) {
    this.setState({typeId: event.target.value});
  }

  onChangeSort(event) {
    this.setState({sortBy: event.target.value});
  }

  onChangeStatus(event) {
    let value = event.target.value
    if(value == null) {
      this.setState({status: null});
    } else {
      this.setState({status: value == "true" ? true : false});
    }
  }

  changePromoteClicksCount(number) {
    this.setState({promotesClickLength: number})
  }

  render() {
    if(this.props.data.loading) { return <div /> }
    let { translate } = this.props
    let { user } = this.props.data
    let dateString = moment(parseInt(user.createdAt)).format('MMM-DD-YYYY');

    return(
      <div>
        <div className="container page">
          <div className="row">
            <div className="col-md-12">
              <div className="page-head">
                <div className="left-info">
                  <div className="back">
                     <Link to={`/dashboard/users/`}>
                      <i className="icon-arrow-left"></i>
                      <span>Back</span>
                    </Link>
                  </div>
                </div>
                <div className="right-info">
                  <div className="action-buttons">
                    <Link to={`/dashboard/edit/users/${user.id}`} className="edit">
                      <i className="icon-edit"></i>
                    </Link>
                    <a href="#" className="remove"
                      onClick={(e) => { if (window.confirm('Are you sure you want to delete this user?')) this.removeUser(user.id) }} >
                      <i className="icon-trash"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>Name</span>
                    <p>{ user.fullName }</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>Email</span>
                    <a href={`mailto:${ user.email }`}>{ user.email }</a>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>Register date</span>
                    <p>{ dateString } </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>Last Login Date</span>
                    <p>Dec-04-2018</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>Country</span>
                    <p>{ user.country }</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>Promotions Clicked</span>
                    <p>236</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="supplier-info">
                    <span>Favourite Categories</span>
                    { user.tags.length > 0 ?
                      <p> { user.tags.map((a) => a.name) }</p>
                    :
                      <p> None </p>
                    }
                  </div>
                </div>


                <div className="col-md-12">
                  <div className="social">
                    <p>Social Media</p>
                      <ul>
                        { user.provider == "facebook" &&
                          <li>
                            <a target={user.facebook ? '_blank' : '' } href={user.facebook ? `https://www.facebook.com/${user.facebook}` : '#' }>
                              <i className="icon-facebook"></i>
                            </a>
                          </li>
                        }
                        { user.provider == "twitter" &&
                          <li>
                            <a target={user.twitter ? '_blank' : '' }
                               href={user.twitter ? `https://www.twitter.com/${user.twitter}` : '#' }>
                              <i className="icon-google"></i>
                            </a>
                          </li>
                        }
                        { user.provider == "instagram" &&
                          <li>
                            <a target={user.instagram ? '_blank' : '' }
                               href={user.instagram ? `https://www.instagram.com/${user.instagram}` : '#' }>
                              <i className="icon-instagram"></i>
                            </a>
                          </li>
                        }
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          { /*
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-12">
                  <div className="list-promotions-title">
                    <p>List of promotions</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="list-promotions">
                    <div className="promotion">
                      <div className="item">
                        <div className="thumbnail">
                          <img src="img/images/jacket.jpg" />
                        </div>
                        <div className="content">
                          <h4 className="title">Wooden bar stool</h4>
                          <div className="price">
                            <p className="value">CHF <span>18.99</span></p>
                            <p className="discount">120.85</p>
                          </div>
                        </div>
                      </div>
                      <div className="sale-number">
                        <p>7357 Sales</p>
                        <span>-55%</span>
                      </div>
                    </div>
                    <div className="promotion">
                      <div className="item">
                        <div className="thumbnail">
                          <img src="img/images/jacket.jpg" />
                        </div>
                        <div className="content">
                          <h4 className="title">Wooden bar stool</h4>
                          <div className="price">
                            <p className="value">CHF <span>18.99</span></p>
                            <p className="discount">120.85</p>
                          </div>
                        </div>
                      </div>
                      <div className="sale-number">
                        <p>7357 Sales</p>
                        <span>-55%</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="list-promotions">
                    <div className="promotion">
                      <div className="item">
                        <div className="thumbnail">
                          <img src="img/images/jacket.jpg" />
                        </div>
                        <div className="content">
                          <h4 className="title">Wooden bar stool</h4>
                          <div className="price">
                            <p className="value">CHF <span>18.99</span></p>
                            <p className="discount">120.85</p>
                          </div>
                        </div>
                      </div>
                      <div className="sale-number">
                        <p>7357 Sales</p>
                        <span>-55%</span>
                      </div>
                    </div>
                    <div className="promotion">
                      <div className="item">
                        <div className="thumbnail">
                          <img src="img/images/jacket.jpg" />
                        </div>
                        <div className="content">
                          <h4 className="title">Wooden bar stool</h4>
                          <div className="price">
                            <p className="value">CHF <span>18.99</span></p>
                            <p className="discount">120.85</p>
                          </div>
                        </div>
                      </div>
                      <div className="sale-number">
                        <p>7357 Sales</p>
                        <span>-55%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          */}
          </div>


          <div class="container m-t-50">
            <div class="row justify-content-between">
              <div class="col-md-4 d-flex align-items-center">
                <div class="list-text">
                  <p>Total of promotions: <span>{user.promoteClicks.length}</span></p>
                </div>
              </div>
              <div class="col-md-8">
                <div class="row justify-content-end">
                  <div class="col-md-4">
                    <div class="select-custom select-full-width">
                      <select class="form-control" onChange={this.onChangeFilter.bind(this)} value={this.state.typeId}>
                        <option value={1}>
                          { translate('dashboard.filters.all') }
                        </option>
                        <option value={2}>
                          { translate('dashboard.filters.sale') }
                        </option>
                        <option value={3}>
                          { translate('dashboard.filters.top_seller') }
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="select-custom select-full-width">
                      <select class="form-control" onChange={this.onChangeSort.bind(this)} value={this.state.sortBy}>
                        <option value={'createdAt'}>
                          { translate('dashboard.filters.created_at') }
                        </option>
                        <option value={'oldPrice'}>
                          { translate('dashboard.filters.old_price') }
                        </option>
                        <option value={'newPrice'}>
                          { translate('dashboard.filters.new_price') }
                        </option>
                        <option value={'discount'}>
                          { translate('dashboard.filters.discount') }
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="select-custom select-full-width">
                      <select class="form-control" onChange={this.onChangeStatus.bind(this)} value={this.state.status}>
                        <option value={true}>Active</option>
                        <option value={false}>Not Active</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PromoteClicksList
            userId={user.id}
            typeId={this.state.typeId}
            sortBy={this.state.sortBy}
            status={this.state.status}
          />
          <div className="container m-t-50">
            <div className="row">
              <div className="col-md-12">
                <div className="box">
                  <div className="head">
                    <div className="title">
                      <h5>Favorite promotions: {user.favoritePromotes.length}</h5>
                    </div>
                    <div className="filter">
                      { /*
                          <form>
                            <div className="select-custom">
                              <select className="form-control">
                                <option>Day</option>
                                <option>Week</option>
                                <option>Month</option>
                              </select>
                            </div>
                          </form>
                        */
                      }
                    </div>
                  </div>
                  <FavouritePromotes userId={user.id} />
                </div>
              </div>
            </div>
          </div>
       </div>
    )
  }
}

function mapStateToProps({ locale }){
  return { translate: getTranslate(locale) }
}

export default compose(
  connect(mapStateToProps),
  graphql(deleteUser),
  graphql(fetchUser, {
    options: (props) => {  return { variables: { id: props.match.params['user_id'] } } }
  }),
)(User);
