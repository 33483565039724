import React from 'react';
import moment
  from 'moment';
import { Route, Link }
  from "react-router-dom";
import { connect }
   from 'react-redux';

import fetchFavouritePromotes
  from '../../queries/user/fetchFavouritePromotes';
import { getTranslate }
  from 'react-localize-redux';

import { compose, graphql }
  from 'react-apollo';

class FavouritePromotes extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      filter: 1,
      sort: "createdAt",
      status: "Active"
    }
  }

  displayPromotes() {
    var { favouritePromotes } = this.props.data
    let n = 0
    console.log(favouritePromotes)
    return (
      favouritePromotes.map((promote) => {
        n = n + 1
        console.log("asdasd", n)
        if (n < 4 || n == 4) {
          return (
            this.displayFirstPromotes(promote)
          )
        } else {
          return (
            this.displayLastPromotes(promote)
          )
        }
      })
    )
  }

  displayFirstPromotes() {
    var { favouritePromotes } = this.props.data
    let { translate } = this.props;
    let n = 0

    if(favouritePromotes.length != 0) {
      return (
        <div className="col-md-6">
          <div className="body">
            {
              favouritePromotes.map((promote) => {
                n = n + 1
                if (n < 4 || n == 4) {
                  return (
                    this.displayPromote(promote)
                  )
                }
              })
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className="col-md-6">
          <div className="body">
            <p>
              { translate('dashboard.promotions.not_found_any_promotion') }
            </p>
          </div>
        </div>
      )
    }
  }

  displayLastPromotes(promote) {
    var { favouritePromotes } = this.props.data

    let n = 0

    return (
      <div className="col-md-6">
        <div className="body body-scroll">
          {
            favouritePromotes.map((promote) => {
              n = n + 1
              if (n > 4) {
                return (
                  this.displayPromote(promote)
                )
              }
            })
          }
        </div>
      </div>
    )

  }

  displayPromote(promote) {
    return (
      <div className="promotion">
        <div className="item">
          <div className="thumbnail">
            <img src={promote.images[0].image}/ >
          </div>
          <div className="content">
            <h4 className="title">{promote.title}</h4>
            <div className="price">
              <p className="value">CHF <span>{promote.newPrice}</span></p>
              <p className="discount">{promote.oldPrice}</p>
            </div>
          </div>
        </div>
        <div className="sale-number">
          <p>n Sales</p>
          <span>-{promote.discount}%</span>
        </div>
      </div>
    )
  }

  render () {
    if(this.props.data.loading) { return <div /> }
    return(
      <div className="row">
        {this.displayFirstPromotes()}
        {this.displayLastPromotes()}
      </div>
    )
  }
}

function mapStateToProps({ locale, current_user, loading }){
  return { me: current_user, loading,
          translate: getTranslate(locale) }
}

export default compose(
  graphql(fetchFavouritePromotes, {
    options: (props, state) => {  return { variables: { id: parseInt(props.userId) } } }
  }),
  connect(mapStateToProps, { })
)(FavouritePromotes);
