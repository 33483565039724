import { RNS3 } from 'react-native-aws3';

const options = {
  bucket: "salebuddy",
  region: "eu-central-1",
  accessKey: "DXELODY6JQKBNGA6RSTV",
  secretKey: "7Dp/n//sThEdlK4rjUk9CFxCW3mlcm31psGBn+1nsVc",
  awsUrl: "ams3.digitaloceanspaces.com",
  successActionStatus: 201
}

export default async (file) => {
  var image = ''
  await RNS3.put(file, options)
  .progress((e) => console.log(e.percent))
  .then(response => {
    var { postResponse } = response.body
    if(response.status === 201){
      if(options['awsUrl']) {
        image = `https://${options['bucket']}.${options['awsUrl']}/${postResponse.location.substr(postResponse.location.lastIndexOf('/') + 1)}`

      } else {
        image = response.body.postResponse.location
      }
    } else {
      return (
        alert("adasdas")
      )
    }
  })

  return image
}

const defineFile = (image) => {
  const uri = image
  const ext = uri.substr(uri.lastIndexOf('.') + 1);
  const name = Math.round(+new Date()/1000);
  let file = {
    name: "image122.png",
    type: "image122/png",
    uri
  }

  return file
}
