import React from 'react';
import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';
import fetchCategories
  from '../../../queries/category/fetchCategories'
import CategoriesList
  from '../../../components/categories/CategoriesList';

import LIMIT
  from '../../../constants/pagination_limit';

import { connect } 
  from 'react-redux'

import { getTranslate } 
  from 'react-localize-redux';

const OFFSET = 0

class Category extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      offset: OFFSET,
    }
  }

  displayCategoriesList() {
    let { categories } = this.props.data

    return (
      <CategoriesList
        totalItems={categories.count}
        translate={this.props.translate}
        changeOffset={this.changeOffset.bind(this)}
        offset={this.state.offset}
        categories={categories.rows}/>
    )
  }

  changeOffset(e, offset){
    e.preventDefault();

    let { categories } = this.props.data;
    let times = Math.ceil(categories.count/LIMIT)

    if(categories.rows && times < offset ){ return null; }

    this.setState({
      offset
    })

    this.props.data.refetch({ offset: LIMIT*offset, limit: LIMIT })
  }

  render () {
    let { loading, categories } = this.props.data

    let { translate } = this.props

    if(loading) {
      return <div></div>
    }

    return(
      <div>
        <div className="container">
            <div className="row justify-content-end">
                <div className="col-md-6">
                    <div className="row justify-content-end">
                        <div className="col-md-8 d-flex justify-content-end">
                            <div className="add-button">
                              <span>
                                { translate('dashboard.category.add_category') }
                              </span>
                                <Link className="add" to={`/dashboard/categories/new`}>
                                  <i className="icon-add"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {this.displayCategoriesList()}
      </div>
    )
  }
}

function mapStateToProps({ current_user, loading, locale }){
  return { translate: getTranslate(locale) }
}

export default compose(
  connect(mapStateToProps),
  graphql(fetchCategories, {
    options: (props) => {  return { variables: { offset: OFFSET, limit: LIMIT } } }
  })
)(Category);
