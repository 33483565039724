import React from 'react';
import moment from 'moment';

import fetchUser
  from '../../../queries/user/singleUser'

import { graphql, compose }
  from 'react-apollo';

import { Route, Link }
  from "react-router-dom";

import fetchClients
  from '../../../queries/user/fetchClients';

import editUser
  from '../../../mutations/users/editUser'

import { startLoading, moveLoading }
  from '../../../actions/loading'

import { connect }
  from 'react-redux'

import { getTranslate }
  from 'react-localize-redux';

class EditUser extends React.Component {
  state = {}

  componentWillReceiveProps(newProps){
    this.changeUserState(newProps)
  }

  changeUserState(props){
    if(!this.state.user && !props.data.loading){
      var { user } = props.data

      this.setState({
        ...user
      })
    }
  }

  componentDidMount(){
    this.changeUserState(this.props)
  }

  editUser(userId){
    let { startLoading, moveLoading, query  } = this.props;
    this.setState({ errors: [], success_message: null })

    startLoading()
    this.props.mutate({
      variables: { ...this.state },
    }).then(res => {
      this.setState({
        success_message: 'The information was updated successfully'
      });

      moveLoading()
    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({success_message: null, errors: errors[0]})

      moveLoading()
    })
  }

  render() {
    if(this.props.data.loading) { return <div /> }

    let { translate } = this.props;

    const { fullName,
            country,
            createdAt,
            email,
          } = this.state

    var date = moment(parseInt(createdAt)).format("YYYY-MM-DD");

    return(
      <div className="container page">
        <div className="row">
          <div className="col-md-12">
            <div className="page-head">
              <div className="left-info">
                <div className="back">
                   <Link to={`/dashboard/users/`}>
                    <i className="icon-arrow-left"></i>
                    <span>
                      { translate('dashboard.actions.back') }
                    </span>
                  </Link>
                </div>
              </div>
              <div className="page-title">
                <h5>
                  { translate('dashboard.actions.edit') }
                </h5>
              </div>
            </div>
          </div>
        </div>
        { this.state.success_message ?
         <p style={{color: 'green', marginBottom: 10}}>{this.state.success_message}</p>
        :
         <p style={{color: 'red', marginBottom: 10}}>{this.state.errors}</p>
        }
        <div className="row justify-content-center">
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    { translate('dashboard.users.name') }
                  </label>
                  <input type="text"
                    className="form-control"
                    value={fullName || ''}
                    onChange={(res) => this.setState({ fullName: res.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    { translate('dashboard.users.email') }
                  </label>
                  <input type="email"
                    className="form-control"
                    value={email || ''}
                    onChange={(res) => this.setState({ email: res.target.value })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    { translate('dashboard.filters.register_date') }
                  </label>
                  <input type="date"
                    className="form-control"
                    value={date || ''}
                    disabled={true}
                    onChange={(res) => this.setState({ createdAt: res.target.value })} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    { translate('dashboard.users.country') }
                  </label>
                  <input type="text"
                    className="form-control"
                    value={country || ''}
                    onChange={(res) => this.setState({ country: res.target.value })} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="social-media-edit">
                  <p>{translate('dashboard.users.social_media_connected')}</p>
                  <ul className="social-media">
                    <li>
                      <span>Facebook</span>
                      <div className="form-group">
                        <input disabled={true}
                               type="text"
                               value={this.state.facebook || ''}
                               className="form-control"
                               onChange={(res) => this.setState({ facebook: res.target.value })} />
                      </div>
                    </li>
                    <li>
                      <span>Twitter</span>
                      <div className="form-group">
                        <input disabled={true}
                               type="text"
                               value={this.state.twitter || ''}
                               className="form-control"
                               onChange={(res) => this.setState({ twitter: res.target.value })} />
                      </div>
                    </li>
                    <li>
                      <span>Instagram</span>
                      <div className="form-group">
                        <input disabled={true}
                               type="text"
                               value={this.state.instagram || ''}
                               className="form-control"
                               onChange={(res) => this.setState({ instagram: res.target.value })} />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 justify-content-end">
                <div className="form-group">
                  <button type="button"
                    onClick={ this.editUser.bind(this) }
                    className="btn btn-primary btn-full-width">
                    { translate('dashboard.actions.save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps({ locale }){
  return { translate: getTranslate(locale), }
}

export default compose(
  graphql(editUser),
  graphql(fetchUser, {
    options: (props) => {  return { variables: { id: props.match.params['user_id'] } } }
  }),
  connect(mapStateToProps, { startLoading, moveLoading })
)(EditUser);
