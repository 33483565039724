import React from 'react'
import { getToken }
  from '../../services/token'

const Home = (props) => {
  if(getToken){
    props.history.push('/dashboard/statistics')
  }else{
    props.history.push('/auth')
  }

  return <div/>
}

export default Home
