import React from 'react';

import { graphql, compose }
  from 'react-apollo';

import fetchCategories
  from '../../../queries/category/fetchCategories';
import fetchCategory
  from '../../../queries/category/fetchCategory';

import updateCategory
  from '../../../mutations/dashboard/categories/updateCategory';

import { connect }
  from 'react-redux'

import { getTranslate }
  from 'react-localize-redux';

import LIMIT
  from '../../../constants/pagination_limit';

const OFFSET = 0

class EditCategory extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      categoryName: '',
      error: '',
      language: 'en'
    }
  }

  componentDidMount() {
    this.setState({language: this.state.language})
    if(this.props.data.category) {
      this.setState({categoryName: this.props.data.category.name})
    }
  }

  componentWillReceiveProps(nextProps){
    let { language } = this.state
    if(this.props.data != nextProps.data) {
      if(language == "en") {
        this.setState({categoryName: nextProps.data.category.name})
      } else {
        let translationCategory = nextProps.data.category.translations.filter((trans) => trans.language == this.state.language)
        this.setState({categoryName: translationCategory[0].name})
      }
    }
  }

  requestToUpdateCategory() {
    var { categoryName } = this.state
    var { category } = this.props.data

    if(categoryName == '') {
      this.setState({error: "Name field are empty!"})
      setTimeout( () => {
        this.setState({error: ''})
        },
      2000);
      return
    }

    this.props.mutate({
      variables: { id: category.id, name: categoryName, language: this.state.language },
      refetchQueries: [{ query: fetchCategories,
                        variables: { offset: OFFSET, limit: LIMIT } }]
    }).then(res => {
      alert("Category update successfully")
      // this.props.history.goBack()
      // let translationCategory = res.data.updateCategory.translations.filter((trans) => trans.language == this.state.language)
      // this.setState({categoryName: category.name})
      this.setState({language: this.state.language})

    }).catch(res => {
      const errors = res.graphQLErrors.map((err) => err.message)
      this.setState({error: errors[0]})
      setTimeout( () => {
        this.setState({error: ''})
        },
      2000);
    })
  }

  addLanguageOptions() {
    let languages = ["en", "de", "fr", "it"]
    return (
      languages.map((language, idx) => {
        return (
          <option key={idx} value={language}>{language}</option>
        )
      })
    )
  }

  onChangeLanguage(event) {
    this.setState({language: event.target.value});
    this.changeStateOfCategory(event.target.value)
  }

  changeStateOfCategory(currentLanguage) {
    let { category } = this.props.data
    let translationCategory = category.translations.filter((trans) => trans.language == currentLanguage)

    if(currentLanguage == "en") {
      this.setState({categoryName: category.name})
      return
    }

    if (translationCategory.length == 0) {
      this.setState({categoryName: ''})
    } else {
      this.setState({categoryName: translationCategory[0].name})
    }
  }

  render () {
    var { loading, category } = this.props.data

    let { translate } = this.props

    if(!loading) {
      return(
        <div>
          <div className="container page">
              <div className="row">
                  <div className="col-md-12">
                      <div className="page-head">
                          <div className="left-info">
                              <div className="back">
                                  <button
                                    onClick={() => this.props.history.goBack()}
                                    type="button">
                                      <i className="icon-arrow-left"></i>
                                      <span>
                                        { translate('dashboard.actions.back')}
                                      </span>
                                  </button>
                              </div>
                          </div>
                          <div className="page-title">
                            <h5>
                              { translate('dashboard.category.edit_category')}
                            </h5>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="row justify-content-center">
                  <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-4">
                            <p style={{fontSize: 12, color: 'red'}}>{this.state.categoryError}</p>
                            <div className="form-group select-custom select-full-width">
                              <select className="form-control"
                                onChange={this.onChangeLanguage.bind(this)}
                                value={this.state.language}>
                                {this.addLanguageOptions()}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p style={{color: 'red', marginBottom: 10}}>{this.state.error}</p>
                            <div className="form-group">
                              <label>
                                { translate('dashboard.users.name')}
                              </label>
                              <input
                                type="text"
                                name="name"
                                className="form-control"
                                value={this.state.categoryName}
                                onChange={(e) => this.setState({categoryName: e.target.value})} />
                            </div>
                          </div>

                          <div className="col-md-12">
                          </div><br />
                          <div className="col-md-6 justify-content-end">
                              <div className="form-group">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-full-width"
                                    onClick={() => this.requestToUpdateCategory()}>
                                        { translate('dashboard.actions.update')}
                                  </button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

function mapStateToProps({ current_user, loading, locale }){
  return { translate: getTranslate(locale) }
}

export default compose(
  connect(mapStateToProps),
  graphql(updateCategory),
  graphql(fetchCategory, {
    options: (props) => {  return { variables: { id: props.match.params['category_id']} } }
  })
)(EditCategory);