import React from 'react';
import { Route, Link }
  from "react-router-dom";
import FavIvonLogo
  from '../../assets/images/logo_favicon/logo.svg';

import ShowGuestPromote
  from './ShowGuestPromote';

import query
  from '../../queries/currentUser';
import { connect }
  from 'react-redux'
import { compose, graphql }
  from 'react-apollo';

import { removeToken }
  from '../../services/token'
import { current_user }
  from '../../actions/user'

import LoadingBar
  from "react-top-loading-bar";
import { finishLoading }
  from '../../actions/loading'


class Guest extends React.Component {

  defineRoutes() {
    return (
      <div style={{marginTop: 100}}>
        <section className="promotion-details" data-animation="fadeIn" data-animation-delay="150">
          <Route exact path="/guest/promote/:promote_id" component={ShowGuestPromote} />
        </section>
      </div>
    )
  }

  render() {
    return(
      <div>
        <LoadingBar
          progress={this.props.loading}
          height={3}
          color="red"
          onLoaderFinished={() => this.props.finishLoading() }
        />
        <header className="main-header">
          <div className="header">
            <div className="logo">
              <img src={FavIvonLogo} />
            </div>
          </div>
          <div className="sub-header screen-desktop">
            <div className="screens">
              <a href="#">{this.props.me ? this.props.me.fullName : ''} <i className="icon-logout"></i></a>
            </div>
          </div>
        </header>
        {this.defineRoutes()}
      </div>
    )
  }
}

function mapStateToProps({ current_user, loading }){
  return { me: current_user, loading }
}

export default compose(
  graphql(query),
  connect(mapStateToProps, { current_user, finishLoading })
)(Guest);
