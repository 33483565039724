import gql from 'graphql-tag'

export default gql`
  mutation UpdateCategory($id: ID!, $language: String!, $name: String!){
    updateCategory(id:$id, language:$language, name:$name) {
      id
      name
      translations {
        language
        name
      }
    }
  }
`
