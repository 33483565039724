import React from 'react';
import moment from 'moment';
import { Route, Link }
  from "react-router-dom";

import { graphql, compose }
  from 'react-apollo';
import fetchCategories
  from '../../queries/category/fetchCategories';
import deleteCategory
  from '../../mutations/dashboard/categories/deleteCategory';

import LIMIT
  from '../../constants/pagination_limit';

const OFFSET = 0


class CategoriesList extends React.Component {
  displayList() {
    var { categories } = this.props
    var _this = this

    return (
      categories.map((category, idx) => {
        var dateString = moment(category.createdAt).format('MMM-DD-YYYY');
        return (
          <tr key={idx}>
              <td>{category.name}</td>
              <td>{category.useId ? category.user.email : ''}</td>
              <td>{dateString}</td>
              <td>{category.promotesLength}</td>
              <td>
                  <div className="action-table">
                      <Link className="edit" to={`/dashboard/category/${category.id}`}>
                        <i className="icon-edit"></i>
                      </Link>
                      {this.displayDeleteButton(category, _this)}
                  </div>
              </td>
          </tr>
        )
      })
    )
  }

  displayDeleteButton(category, _this) {
    if(category.name != "Other") {
      return (
        <a
          href="#"
          className="remove"
          onClick={() => _this.requestToDeleteCategory(category.id)}><i className="icon-trash"></i></a>
      )
    }
  }

  requestToDeleteCategory(categoryId) {
    var { translate, categories } = this.props
    
    var result = window.confirm(translate('dashboard.category.sure_to_delete'));
    if (result) {
      this.props.mutate({
        variables: { id: categoryId },
        refetchQueries: [{ query: fetchCategories,
            variables: {  offset: OFFSET, limit: LIMIT }}]
      }).then(res => {
        alert(translate('dashboard.category.category_deleted'))
        this.props.history.goBack()
      }).catch(res => {
      })
    }
  }

  showPagination(){
    let paginations = []
    let times = Math.ceil(this.props.totalItems/LIMIT)
    for(let i=0; i <= times - 1; i++){
      paginations.push(<li key={i} className="page-item">
        <a className={`page-link ${this.props.offset == i ? 'active' : ''}`}
           onClick={(e) => this.props.changeOffset(e, i)}>
          { i+1 }
        </a>
      </li>)
    }

    return paginations
  }

  render () {
    let { translate } = this.props
    let times = Math.ceil(this.props.totalItems/LIMIT)

    return(
      <div className="container">
          <div className="row">
              <div className="col-md-12">
                  <div className="primary-table">
                      <table className="table">
                          <thead>
                              <tr>
                                <th>
                                { translate('dashboard.promotions.title') }
                                </th>
                                <th>
                                { translate('dashboard.category.added_by') }
                                </th>
                                <th>
                                { translate('dashboard.filters.register_date') }
                                </th>
                                <th>
                                { translate('dashboard.category.number_of_products') }
                                </th>
                                <th>
                                { translate('dashboard.users.action') }
                                </th>
                              </tr>
                          </thead>
                          <tbody>
                            {this.displayList()}
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-md-12 d-flex justify-content-center">
                { ( this.props.offset || this.props.offset == 0 ) &&
                  <ul className="pagination">
                    { this.props.offset != 0 &&
                      <li className="page-item">
                        <a className="page-link"
                          onClick={(e) => this.props.changeOffset(e, this.props.offset - 1)} >
                          <i className="icon-arrow-left"></i>
                        </a>
                      </li> }
                   { this.showPagination() }
                   { this.props.offset < times-1 &&
                      <li className="page-item">
                        <a className="page-link"
                          onClick={(e) => this.props.changeOffset(e, this.props.offset + 1)} >
                          <i className="icon-arrow-right"></i>
                        </a>
                      </li> }
                  </ul>
                }
              </div>
          </div>
      </div>
    )
  }
}

export default compose(
  graphql(deleteCategory),
)(CategoriesList)
