import React from 'react';

import { Route, Link }
  from "react-router-dom";

import ShowSupplierList
  from './ShowSupplierList'

import { compose, graphql }
  from 'react-apollo';

class SuppliersList extends React.Component {
  render () {
    let { translate, mutate, offset, suppliers } = this.props

    let { rows, count } = suppliers
    
    return(
      <ShowSupplierList 
        mutate={mutate}
        offset={offset}
        translate={translate}
        totalItems={count}
        changeOffset={this.props.changeOffset}
        users={rows} />
    )
  }
}

export default SuppliersList
