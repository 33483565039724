import gql from 'graphql-tag'

export default gql`
   mutation AddNewSupplier($email: String!,
        $fullName:String!,
        $phone:String, $street:String,
        $number:String, $zip:String,
        $city:String, $description:String,
        $image:String,
        $paymentMethod:String){
      addNewSupplier(fullName: $fullName,
          email: $email, phone:$phone,
          street:$street, number:$number,
          zip:$zip, city:$city,
          description:$description,
          image:$image,
          paymentMethod:$paymentMethod){
       id
       fullName
       email
    }
  }
`
